<template>
  <b-loading
    v-if="loading"
    v-model="loading"
    :can-cancel="false"
    :is-full-page="true"
  />
  <div v-else>
    <h1>{{ $t("UI.general.Dashboard") }}</h1>
    <div class="columns is-multiline">
      <div class="column">
        <article class="panel is-primary">
          <p class="panel-heading">{{ $t("UI.dashboard.casesTotal") }}</p>
          <p @click="toOverview()" class="huge">
            {{ computedAnalytics.allCases }}
          </p>
        </article>
      </div>
      <div class="column">
        <article class="panel">
          <p class="panel-heading">{{ $t("UI.dashboard.casesOpen") }}</p>
          <p @click="toOverviewOpen()" class="huge">
            <b-tooltip
              :label="toPercentage(computedAnalytics.casesOpen)"
              position="is-right"
              type="is-white"
            >
              {{ computedAnalytics.casesOpen }}
            </b-tooltip>
          </p>
        </article>
      </div>
      <div class="column">
        <article class="panel is-info">
          <p class="panel-heading">{{ $t("UI.dashboard.casesInProgress") }}</p>
          <p @click="toOverviewInProgress()" class="huge">
            <b-tooltip
              :label="toPercentage(computedAnalytics.casesInProgress)"
              position="is-right"
              type="is-info"
            >
              {{ computedAnalytics.casesInProgress }}
            </b-tooltip>
          </p>
        </article>
      </div>
      <div class="column">
        <article class="panel is-success">
          <p class="panel-heading">{{ $t("UI.dashboard.casesClosed") }}</p>
          <p @click="toOverviewClosed()" class="huge">
            <b-tooltip
              :label="toPercentage(computedAnalytics.casesClosed)"
              position="is-right"
              type="is-success"
            >
              {{ computedAnalytics.casesClosed }}
            </b-tooltip>
          </p>
        </article>
      </div>
      <div class="column">
        <article class="panel is-warning">
          <p class="panel-heading">
            {{ $t("UI.dashboard.canceled") }}
          </p>
          <p @click="toOverviewCanceled()" class="huge">
            <b-tooltip
              :label="toPercentage(computedAnalytics.casesCanceled)"
              position="is-right"
              type="is-warning"
            >
              {{ computedAnalytics.casesCanceled }}
            </b-tooltip>
          </p>
        </article>
      </div>
      <div class="column">
        <article class="panel is-danger">
          <p class="panel-heading">
            {{ $t("UI.dashboard.withoutAppointment") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.withoutAppointment')"
              :multilined="true"
              position="is-left"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <p @click="toOverviewWithoutAppointment()" class="huge">
            {{ computedAnalytics.casesWithoutAppointment }}
          </p>
        </article>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.newCases") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.newCases')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <apexchart
            v-if="createdCasesByMonthLength > 0"
            type="bar"
            :options="createdCases.options"
            :series="createdCases.series"
            height="500rem"
          ></apexchart>
          <div v-else class="py-5">
            <p class="has-text-centered has-text-weight-semibold is-size-4">
              {{ $t("UI.dashboard.noCasesFound") }}
            </p>
          </div>
        </article>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.closedCases") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.closedCases')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <apexchart
            v-if="closedCasesByWeekLength > 0"
            type="bar"
            :options="closedCases.options"
            :series="closedCases.series"
            height="500rem"
          ></apexchart>
          <div v-else class="py-5">
            <p class="has-text-centered has-text-weight-semibold is-size-4">
              {{ $t("UI.dashboard.noClosedCases") }}
            </p>
          </div>
        </article>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.repairDecisions") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.repairDecisions')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <apexchart
            type="donut"
            :options="repairWanted.options"
            :series="repairWanted.series"
            height="300rem"
          ></apexchart>
        </article>
      </div>
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.advisories") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.advisories')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <apexchart
            type="donut"
            :options="advisories.options"
            :series="advisories.series"
            height="300rem"
          ></apexchart>
        </article>
      </div>
    </div>
    <div class="columns regulations">
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.regulationRatios") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.regulationRatios')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <a
            v-for="(key, index) in Object.keys(
              computedAnalytics.regulationRatios
            )"
            :key="index"
            class="panel-block is-flex justify-content-space-between"
          >
            <span class="is-flex">{{
              $t("UI.enum.regulation_types." + key)
            }}</span>
            <div class="is-flex">
              <span class="is-flex has-text-right middle">{{
                computedAnalytics.regulationRatios[key] | rawPercentage
              }}</span>
              <b-icon
                :icon="icon(computedAnalytics.regulationRatios[key])"
                :type="rating(computedAnalytics.regulationRatios[key])"
                size="is-large"
              />
            </div>
          </a>
        </article>
      </div>
      <div class="column">
        <article class="panel">
          <p class="panel-heading">
            {{ $t("UI.dashboard.regulationSums") }}
            <b-tooltip
              :label="$t('UI.dashboard.tooltips.regulationSums')"
              :multilined="true"
              type="is-white"
            >
              <b-icon icon="question-circle" size="is-small" />
            </b-tooltip>
          </p>
          <a
            v-for="(key, index) in Object.keys(
              computedAnalytics.regulationSums
            )"
            :key="index"
            class="panel-block is-flex justify-content-space-between add-padding"
          >
            <span class="is-flex">{{
              $t("UI.enum.regulation_types." + key)
            }}</span>
            <span class="is-flex has-text-right">{{
              computedAnalytics.regulationSums[key] | currency
            }}</span>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.justify-content-space-between {
  justify-content: space-between;
}
.huge {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.middle {
  margin: auto 0;
}
.add-padding {
  padding-top: 1.2rem;
  padding-bottom: 1.3rem;
}
.regulations .panel a:hover {
  cursor: default;
}
</style>

<script lang="js">
import { AnalyticsHttpClient } from "@/common/http";
import moment from "moment";
import i18n from "@/ui/i18n";
import {
  CaseFilters,
  LocalStorageConstants,
  RepairDecision,
  Status,
} from "@/common/enums";
import { NamedRoutes } from "@/ui/router";
import { LocalStorageHelpers } from "@/common/helpers";

export default {
  components: {},
  data() {
    return {
      computedAnalytics: {},
      loading: true,
    };
  },
  methods: {
    toPercentage(value) {
      const totalCases = this.computedAnalytics.allCases;
      if (!totalCases || !value) return "0";
      const percentage = (value / totalCases) * 100;
      return `${percentage.toFixed(0)} %`;
    },
    rating(percentage) {
      if (percentage > 0.9) {
        return "is-success";
      } else if (percentage > 0.6) {
        return "is-warning";
      } else {
        return "is-danger";
      }
    },
    icon: function (percentage) {
      if (percentage > 0.9) {
        return "caret-square-up";
      } else if (percentage > 0.6) {
        return "caret-square-right";
      } else {
        return "caret-square-down";
      }
    },
    toOverviewWithoutAppointment() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      LocalStorageHelpers.store_values([
        LocalStorageHelpers.pair(
          LocalStorageConstants.PRECONFIGURED_FILTER,
          JSON.stringify([CaseFilters.REPAIR_DATE_MISSING])
        ),
        LocalStorageHelpers.pair(
          LocalStorageConstants.STATUS_FILTER,
          JSON.stringify([Status.RELEASED])
        ),
        LocalStorageHelpers.pair(
          LocalStorageConstants.REPAIR_DECISION_FILTER,
          JSON.stringify([RepairDecision.CONCRETE])
        ),
      ]);
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    toOverviewClosed() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      LocalStorageHelpers.store_values([
        LocalStorageHelpers.pair(
          LocalStorageConstants.STATUS_FILTER,
          JSON.stringify([Status.CLOSED])
        ),
      ]);
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    toOverviewInProgress() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      LocalStorageHelpers.store_values([
        LocalStorageHelpers.pair(
          LocalStorageConstants.STATUS_FILTER,
          JSON.stringify([Status.RELEASED])
        ),
      ]);
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    toOverviewOpen() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      LocalStorageHelpers.store_values([
        LocalStorageHelpers.pair(
          LocalStorageConstants.STATUS_FILTER,
          JSON.stringify([Status.WORK_IN_PROGRESS])
        ),
      ]);
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    toOverview() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());

      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    toOverviewCanceled() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      LocalStorageHelpers.store_values([
        LocalStorageHelpers.pair(
          LocalStorageConstants.STATUS_FILTER,
          JSON.stringify([Status.CANCELLED])
        ),
      ]);
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
  },
  computed: {
    createdCases() {
      if (this.createdCasesByMonthLength === 0) {
        return [];
      }

      return {
        options: {
          xaxis: {
            labels: {
              formatter: function (aCase) {
                if (typeof aCase === "string") {
                  const splits = aCase.split("-");
                  return `${splits[1]}/${splits[0]}`;
                }
                return "N/A";
              },
            },
            categories: this.computedAnalytics.createdCasesByMonth.labels,
          },
          sort: {
            comparer: (a, b) => {
              const obj1 = moment(a, "YYYY-ww");
              const obj2 = moment(b, "YYYY-ww");
              return obj1.isBefore(obj2) ? -1 : obj1.isAfter(obj2) ? 1 : 0;
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
        },
        series: this.computedAnalytics.createdCasesByMonth.records
      };
    },
    closedCases() {
      if (this.closedCasesByWeekLength === 0) {
        return [];
      }

      return {
        options: {
          xaxis: {
            labels: {
              formatter: function (aCase) {
                if (typeof aCase === "string") {
                  const splits = aCase.split("-");
                  return `KW ${splits[1]}/${splits[0]}`;
                }
                return "N/A";
              },
            },
            categories: this.computedAnalytics.closedCasesByWeek.labels,
          },
          sort: {
            comparer: (a, b) => {
              const obj1 = moment(a, "YYYY-ww");
              const obj2 = moment(b, "YYYY-ww");
              return obj1.isBefore(obj2) ? -1 : obj1.isAfter(obj2) ? 1 : 0;
            },
          },
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
        },
        series: this.computedAnalytics.closedCasesByWeek.records,
      };
    },
    repairWanted() {
      const labels = Object.keys(this.computedAnalytics.repairWanted)
                            .map((k) => i18n.t(`UI.enum.repair_wanted.${k}`));
      const values = Object.values(this.computedAnalytics.repairWanted);

      return {
        options: {
          labels: labels,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                  },
                },
              },
            },
          },
        },
        series: values,
      };
    },
    advisories() {
      const labels = Object.keys(this.computedAnalytics.advisories)
                            .map((k) =>  i18n.t(`UI.enum.advisory.${k}`));
      const values = Object.values(this.computedAnalytics.advisories);

      return {
        options: {
          labels: labels,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                  },
                },
              },
            },
          },
        },
        series: values,
      };
    },
    createdCasesByMonthLength() {
      return this.computedAnalytics.createdCasesByMonth.labels.length;
    },
    closedCasesByWeekLength() {
      return this.computedAnalytics.closedCasesByWeek.labels.length;
    },
  },
  created() {
    window.scrollTo(0, 0);
    new AnalyticsHttpClient()
      .loadAllAnalytics()
      .then((res) => {
        this.computedAnalytics = res;

      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
