
import Vue from "vue";
import { AdminHttpClient } from "@/common/http";
import { LocationDTO } from "@/common/dto";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { LocationType } from "@/common/enums";

export default Vue.extend({
  components: {},
  data() {
    return {
      caseHandlerLoading: false,
      addInsuranceLoading: false,
      addLocationsLoading: false,

      selectedCaseHandler: new LocationDTO(
        "",
        i18n.t("UI.enum.general.SELECT"),
        "",
        false,
        LocationType.LOCATION
      ),

      caseHandlers: [] as LocationDTO[],
    };
  },
  methods: {
    addInsurances: function () {
      this.addInsuranceLoading = true;
      new AdminHttpClient()
        .addInsurancesToCaseHandler(this.selectedCaseHandler.locationCode)
        .catch((err) =>
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.admin.addInsuranceLoading"),
            err.message
          )
        )
        .finally(() => (this.addInsuranceLoading = false));
    },
    addPartners: function () {
      this.addLocationsLoading = true;
      new AdminHttpClient()
        .addLocationsToCaseHandler(this.selectedCaseHandler.locationCode)
        .catch((err) =>
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.admin.addInsuranceLoading"),
            err.message
          )
        )
        .finally(() => (this.addLocationsLoading = false));
    },
  },
  computed: {
    isServerSelected: function (): boolean {
      return this.selectedCaseHandler.locationCode !== "";
    },
  },
  async created() {
    window.scrollTo(0, 0);

    this.caseHandlerLoading = true;
    new AdminHttpClient()
      .loadAllCaseHandlers()
      .then((res) => (this.caseHandlers = res))
      .catch((err) =>
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.admin.loadCaseHandler"),
          err.message
        )
      )
      .finally(() => (this.caseHandlerLoading = false));
  },
});
