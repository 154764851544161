
import Vue from "vue";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { HistoryHttpClient } from "@/common/http";
import {
  DiffDTO,
  EventChangeDTO,
  HistoryDTO,
  HistoryItemDTO,
} from "@/common/dto";
import moment from "moment";
import { DiffReason, DiffType } from "@/common/enums";
import { DiffDetails } from "@/ui/components/detail/history";

export default Vue.extend({
  components: { DiffDetails },
  props: {
    caseId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      history_by_date: [] as HistoryDTO[],
      loading: true,
      moment,
      DiffType,
      DiffDTO,
    };
  },
  async mounted() {
    await new HistoryHttpClient()
      .loadHistory(this.caseId)
      .then((res) => {
        this.history_by_date = res;
        this.loading = false;
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.history.loading"),
          err.message
        );
      });
  },
  computed: {},
  methods: {
    entry_icon: function (item: HistoryItemDTO): string {
      const type = item.type;
      if (type === DiffType.COMMENT) {
        return "comments";
      } else if (type === DiffType.ATTACHMENT) {
        return "file";
      } else if (
        type === DiffType.CASE ||
        type === DiffType.EVALUATION_VALUES
      ) {
        return "cog";
      } else if (type === DiffType.EVENT) {
        const changes = item.changes as EventChangeDTO;
        if (changes != undefined && changes.type === "TAG") {
          return "tag";
        }
        return "star";
      } else if (type === DiffType.ATTACHMENT_TAG) {
        return "tag";
      }

      return "question-circle";
    },
    icon_color: function (reason: DiffReason): string {
      if (reason == DiffReason.ADDED) {
        return "is-success";
      } else if (reason == DiffReason.DELETED) {
        return "is-danger";
      } else if (reason == DiffReason.MODIFIED) {
        return "is-warning";
      }
      return "is-primary";
    },
    get_details: function (item: HistoryItemDTO): string {
      const prefix_key = "UI.enum.diffType." + item.type;
      const suffix_key = "UI.enum.diffReason." + item.reason;

      let detail = "";

      if (item.type == DiffType.ATTACHMENT) {
        const changes = item.changes as Record<string, DiffDTO>;
        if (changes != undefined) {
          const value = (
            item.reason === DiffReason.DELETED ? "oldValue" : "newValue"
          ) as keyof DiffDTO;

          if (
            changes["file_name"] != undefined &&
            typeof changes["file_name"][value] == "string"
          ) {
            detail = changes["file_name"][value] as string;
          }
        }
      } else if (item.type == DiffType.ATTACHMENT_TAG) {
        detail = this.attachment_tag_changes(item);
      } else if (item.type === DiffType.EVENT) {
        const changes = item.changes as EventChangeDTO;
        if (changes != undefined) {
          const type_key = `UI.enum.diffEvent.${changes.type}`;
          const info_key = `UI.enum.${changes.type.toLowerCase()}.${
            changes.name
          }`;

          if (i18n.te(type_key) && !i18n.te(info_key)) {
            return `${i18n.t(type_key)}: ${changes.name} ${i18n.t(suffix_key)}`;
          }

          if (i18n.te(type_key) && i18n.te(info_key)) {
            return `${i18n.t(type_key)} <i>${i18n.t(info_key)}</i> ${i18n.t(
              suffix_key
            )}`;
          }
        }
      }

      if (detail.length > 0) {
        return `${i18n.t(prefix_key)} <i>${detail}</i> ${i18n.t(suffix_key)}`;
      }

      return `${i18n.t(prefix_key)} ${i18n.t(suffix_key)}`;
    },
    attachment_tag_changes: function (item: HistoryItemDTO) {
      const changes = item.changes as Record<string, DiffDTO>;
      let detail = "";

      if (changes != undefined) {
        const new_tag = this.get_change_as_string(changes, "tag", true);
        const old_tag = this.get_change_as_string(changes, "tag", false);
        const file_name = this.get_change_as_string(changes, "file_name", true);

        const new_tag_key = `UI.enum.attachment_tag.${new_tag}`;
        const old_tag_key = `UI.enum.attachment_tag.${old_tag}`;

        if (item.reason === DiffReason.ADDED && i18n.te(new_tag_key)) {
          const new_tag_text = i18n.t(new_tag_key);
          detail = i18n.t("UI.history.attachment_added_tag", {
            tag: new_tag_text,
            file_name: file_name,
          });
        } else if (
          item.reason === DiffReason.MODIFIED &&
          i18n.te(old_tag_key) &&
          i18n.te(new_tag_key)
        ) {
          const new_tag_text = i18n.t(new_tag_key);
          const old_tag_text = i18n.t(old_tag_key);
          detail = i18n.t("UI.history.attachment_modified_tag", {
            new_tag: new_tag_text,
            old_tag: old_tag_text,
            file_name: file_name,
          });
        } else if (item.reason === DiffReason.DELETED && i18n.te(old_tag_key)) {
          const old_tag_text = i18n.t(old_tag_key);
          detail = i18n.t("UI.history.attachment_deleted_tag", {
            tag: old_tag_text,
            file_name: file_name,
          });
        }
      }

      return detail;
    },
    get_change_as_string(
      changes: Record<string, DiffDTO>,
      name: string,
      newValue: boolean
    ) {
      const key = newValue ? "newValue" : "oldValue";
      if (changes[name] != undefined && typeof changes[name][key] == "string") {
        return changes[name][key];
      }
    },
  },
});
