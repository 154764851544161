
import Vue, { PropType } from "vue";
import { AttachmentTag } from "@/common/enums";
import { TagChangedEvent } from "@/common/types";

export default Vue.extend({
  name: "TagSelect",
  components: {},
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: String as PropType<AttachmentTag>,
      required: true,
    },
    fileId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      AttachmentTag,
    };
  },
  computed: {
    localValue: {
      get: function (): AttachmentTag {
        return this.value;
      },
      set: function (value: AttachmentTag) {
        this.$emit("changed", {
          value,
          fileId: this.fileId,
        } as TagChangedEvent);
      },
    },
  },
});
