
import Vue, { PropType } from "vue";
import { DiffDataType } from "@/common/enums";
import moment from "moment";
import i18n from "@/ui/i18n";
import LocationName from "@/ui/components/detail/history/LocationName.vue";
import InsuranceName from "@/ui/components/detail/history/InsuranceName.vue";
import LegalInsuranceName from "@/ui/components/detail/history/LegalInsuranceName.vue";

const decision_fields = [
  "injured_vat_deduction",
  "driver_is_owner",
  "accident_national",
  "police_alerted",
  "insurance_contacted",
  "witness_available",
  "physical_injury",
];

export default Vue.extend({
  components: { LegalInsuranceName, InsuranceName, LocationName },
  props: {
    value: {
      type: String,
    },
    field: {
      type: String,
    },
    type: {
      type: String as PropType<DiffDataType>,
    },
  },
  data() {
    return {
      DiffDataType,
      moment,
    };
  },
  methods: {},
  computed: {
    enum_value: function (): string {
      const key = decision_fields.includes(this.field)
        ? "UI.enum.decision." + this.value
        : this.field.includes("country")
        ? `UI.enum.country.${this.value}`
        : `UI.enum.${this.field}.${this.value}`;

      if (i18n.te(key)) {
        return i18n.t(key);
      }

      return "";
    },
    date_value: function (): string {
      const date = moment(this.value);
      if (date.isValid()) {
        return date.format("DD.MM.YYYY");
      }
      return "";
    },
  },
});
