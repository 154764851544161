
import Vue, { PropType } from "vue";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { TranslateResult } from "vue-i18n/types";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  methods: {
    stringValue: function (str: string) {
      return str + "";
    },
  },
  computed: {
    localValue: {
      get: function (): string {
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
      },
    },
  },
});
