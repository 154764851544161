<template>
  <div v-if="has_content" class="columns is-multiline">
    <div class="column is-full">
      <h3 class="mt-6">{{ $t("UI.detail.evaluationDetails") }}</h3>
      <div class="box">
        <evaluation-date
          v-if="substitutionNoticeDate !== null"
          :label="$t('UI.detail.substitutionNoticeDate')"
          :date="substitutionNoticeDate"
        />
        <evaluation-date
          v-if="advanceRequestDate !== null"
          :label="$t('UI.detail.advanceRequestDate')"
          :date="advanceRequestDate"
        />
        <evaluation-date :label="$t('UI.detail.issueDate')" :date="issueDate" />
        <evaluation
          v-if="has_repair_costs"
          :issued="evalValues.repairCostsIssued"
          :label="repair_label"
          :received="evalValues.repairCostsReceived"
        />
        <evaluation
          v-if="has_rental_costs"
          :issued="evalValues.rentalCostsIssued"
          :label="rental_label"
          :received="evalValues.rentalCostsReceived"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { DataHttpClient } from "@/common/http";
import { EvaluationValuesDTO } from "@/common/dto";
import Evaluation from "@/ui/components/detail/Evaluation";
import EvaluationDate from "@/ui/components/detail/EvaluationDate";
import moment from "moment";

export default Vue.extend({
  name: "EvaluationDetails",
  components: { Evaluation, EvaluationDate },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    fileNumber: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      evalValues: EvaluationValuesDTO.empty_values(),
      moment,
    };
  },
  computed: {
    has_content: function () {
      return (
        this.evalValues.issueDate !== null ||
        this.evalValues.repairInvoiceNo !== "" ||
        this.evalValues.repairCostsIssued > 0 ||
        this.evalValues.repairCostsReceived > 0 ||
        this.evalValues.rentalInvoiceNo !== "" ||
        this.evalValues.rentalCostsIssued > 0 ||
        this.evalValues.rentalCostsReceived > 0 ||
        this.evalValues.substitutionNoticeDate !== null ||
        this.evalValues.advanceRequestDate !== null
      );
    },
    has_repair_costs: function () {
      return (
        this.evalValues.repairCostsIssued > 0 ||
        this.evalValues.repairCostsReceived > 0
      );
    },
    has_rental_costs: function () {
      return (
        this.evalValues.rentalCostsIssued > 0 ||
        this.evalValues.rentalCostsReceived > 0
      );
    },
    repair_label: function () {
      const title = i18n.t("UI.detail.repairInvoice");

      if (this.evalValues.repairInvoiceNo !== "") {
        return (
          title +
          " " +
          i18n.t("UI.detail.numberPrefix", {
            number: this.evalValues.repairInvoiceNo,
          }) +
          ":"
        );
      }

      return title + ":";
    },
    rental_label: function () {
      const title = i18n.t("UI.detail.rentalInvoice");

      if (this.evalValues.rentalInvoiceNo !== "") {
        return (
          title +
          " " +
          i18n.t("UI.detail.numberPrefix", {
            number: this.evalValues.rentalInvoiceNo,
          }) +
          ":"
        );
      }

      return title + ":";
    },
    issueDate: function () {
      const issueDate = moment(this.evalValues.issueDate);
      if (issueDate.isValid()) {
        return issueDate;
      }

      return i18n.t("UI.detail.notIssuedYet");
    },
    substitutionNoticeDate: function () {
      const substitutionNoticeDate = moment(
        this.evalValues.substitutionNoticeDate
      );
      if (substitutionNoticeDate.isValid()) {
        return substitutionNoticeDate;
      }

      return null;
    },
    advanceRequestDate: function () {
      const advanceRequestDate = moment(this.evalValues.advanceRequestDate);
      if (advanceRequestDate.isValid()) {
        return advanceRequestDate;
      }

      return null;
    },
  },
  async created() {
    if (this.fileNumber !== "") {
      await new DataHttpClient()
        .loadEvalValues(this.caseId)
        .then((res) => {
          this.evalValues = res;
        })
        .catch((err) => {
          if (err.response.status !== 404) {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.data.evaluation"),
              err.message
            );
          }
        });
    }
  },
});
</script>
