import { BaseClient } from "@/common/http/BaseClient";

export class ExternalIntegrationsHttpClient extends BaseClient {
  private readonly sendCaseToJLawyerPath = "/j-lawyer/save";

  constructor() {
    super("/external");
  }

  async sendCaseToJLawyer(caseId: string): Promise<boolean> {
    const url = this.urlWithId(this.sendCaseToJLawyerPath, caseId);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
