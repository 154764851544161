<template>
  <div id="main-container">
    <div v-if="loading">
      <b-loading v-model="loading" :can-cancel="false" :is-full-page="true" />
    </div>
    <div v-else class="pdf-container">
      <div id="pdf-viewer-container" ref="viewer"></div>
      <div v-if="attachment.signable" class="buttons mr-4 mb-2 is-right">
        <b-button
          :disabled="!saveEnabled"
          type="is-success"
          @click.prevent="saveDocument()"
          >{{ $t("UI.general.save") }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#container {
  width: 60rem;
  height: 100%;
  background-color: var(--view);
}

#main-container {
  background-color: #f6f8fa;
}
.pdf-container {
  height: 90vh;
}
#pdf-viewer-container {
  background-color: lightgray;
  /* The following is important for the scroll to work properly */
  height: 100%;
  padding: 20px;
}
</style>

<script lang="js">
import { AttachmentHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { AttachmentDTO } from "@/common/dto";
import { mapGetters } from "vuex";
import { configModuleMeta } from "@/ui/store/configuration";
import { DocumentViewer } from "./pdf-signature.js";
import {ATTACHMENT_UPDATED, POA_SIGNED} from "@/common/events";
import { event_bus } from "@/main";
import { AttachmentTag } from "@/common/enums";

export default {
  name: "ShowPdf",
  props: {
    attachment: AttachmentDTO,
  },
  data() {
    return {
      loading: true,
      saveEnabled: false,
      instance: undefined,
      signature: undefined,
    };
  },
  methods: {
    async saveDocument() {
      if (this.signature !== undefined) {
        new AttachmentHttpClient()
          .signAttachment(this.attachment.attachmentId, this.signature)
          .then((res) => {
            BuefyHelpers.show_success_notification(
              this.$buefy,
              i18n.t("UI.message.success"),
              i18n.t("UI.message.attachmentUpdated", { fileName: res.fileName })
            );
            event_bus.$emit(ATTACHMENT_UPDATED);
            event_bus.$emit(POA_SIGNED, this.attachment.attachmentId);
          })
          .catch((err) => {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.attachment.upload"),
              err.message
            );
          })
          .finally(() => {
            this.$emit("close");
          });
      }
    },
    async createViewer(pdfFile) {
      const buffer = await pdfFile.arrayBuffer();
      this.instance = new DocumentViewer(this.$refs.viewer);
      this.instance.renderDocument(buffer, {
        documentTitle: this.filename,
        readOnly: !this.attachment.signable,
        signatureFieldNames: ["unterschreiben"],
        signatureFormat: "point-cloud",
        signatureHint: "Bitte hier unterschreiben",
      });
      this.instance.addDocumentSignedListener((signature) => {
        this.signature = signature;
        this.saveEnabled = signature.some(
          (sig) => sig.signatureCoordinates.length > 0
        );
      });
    },
  },
  computed: {
    ...mapGetters({
      clientUrl: configModuleMeta.getters.getClientUri,
    }),
    filename() {
      if(this.attachment.tag !== AttachmentTag.NONE){
        return i18n.t("UI.enum.attachment_tag." + this.attachment.tag);
      }
      return this.attachment.fileName;
    }
  },
  beforeDestroy() {
    if (this.instance !== undefined) {
      this.instance.dispose();
      this.instance = undefined;
    }
  },
  created() {
    new AttachmentHttpClient()
      .downloadAttachment(this.attachment.attachmentId)
      .then((res) => {
        this.createViewer(res);
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.attachment.load"),
          err.message
        );
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
