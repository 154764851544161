import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/ui/store";
import theme_switcher from "@/common/service/ThemeSwitcher";

export interface ConfigState {
  keycloakUrl: string;
  keycloakClientId: string;
  keycloakRealm: string;
  apiUri: string;
  clientUri: string;
  version: string;
  theme: string;
  language: string;
}

export const state: ConfigState = {
  version: process.env.PACKAGE_VERSION || "ndf",
  keycloakUrl: process.env.KEYCLOAK_URL || "ndf",
  keycloakClientId: process.env.KEYCLOAK_CLIENT_ID || "ndf",
  keycloakRealm: process.env.KEYCLOAK_REALM || "ndf",
  apiUri: process.env.API_URI || "ndf",
  clientUri: process.env.CLIENT_URI || "ndf",
  theme: process.env.THEME || "ndf",
  language: process.env.LANGUAGE || "de-DE",
};

export const actions: ActionTree<ConfigState, RootState> = {
  setKeycloakUrl({ commit }, keycloakUrl: string) {
    commit("setKeycloakUrl", keycloakUrl);
  },
  setKeycloakClientId({ commit }, keycloakClientId: string) {
    commit("setKeycloakClientId", keycloakClientId);
  },
  setKeycloakRealm({ commit }, keycloakRealm: string) {
    commit("setKeycloakRealm", keycloakRealm);
  },
  setApiUri({ commit }, apiUri: string) {
    commit("setApiUri", apiUri);
  },
  setClientUri({ commit }, clientUri: string) {
    commit("setClientUri", clientUri);
  },
  setVersion({ commit }, version: string) {
    commit("setVersion", version);
  },
  setTheme({ commit }, theme: string) {
    commit("setTheme", theme);
  },
  setLanguage({ commit }, language: string) {
    commit("setLanguage", language);
  },
};

export const mutations: MutationTree<ConfigState> = {
  setKeycloakUrl(theState, keycloakUrl: string) {
    theState.keycloakUrl = keycloakUrl;
  },
  setKeycloakClientId(theState, keycloakClientId: string) {
    theState.keycloakClientId = keycloakClientId;
  },
  setKeycloakRealm(theState, keycloakRealm: string) {
    theState.keycloakRealm = keycloakRealm;
  },
  setApiUri(theState, apiUri: string) {
    theState.apiUri = apiUri;
  },
  setClientUri(theState, clientUri: string) {
    theState.clientUri = clientUri;
  },
  setTheme(theState, theme: string) {
    theState.theme = theme;
    theme_switcher.switchTheme(theme);
  },
  setVersion(theState, version: string) {
    theState.version = version;
  },
  setLanguage(theState, language: string) {
    theState.language = language;
  },
};

export const getters: GetterTree<ConfigState, RootState> = {
  getKeycloakUrl(theState) {
    return theState.keycloakUrl;
  },
  getKeycloakClientId(theState) {
    return theState.keycloakClientId;
  },
  getKeycloakRealm(theState) {
    return theState.keycloakRealm;
  },
  getApiUri(theState) {
    return theState.apiUri;
  },
  getClientUri(theState) {
    return theState.clientUri;
  },
  getVersion(theState) {
    return theState.version;
  },
  getTheme(theState) {
    return theState.theme;
  },
  getLanguage(theState) {
    return theState.language;
  },
};

function createName(methodName: string): string {
  return "configModule" + "/" + methodName;
}

export const configModuleMeta = {
  actions: {
    setKeycloakUrl: createName("setKeycloakUrl"),
    setKeycloakClientId: createName("setKeycloakClientId"),
    setKeycloakRealm: createName("setKeycloakRealm"),
    setApiUri: createName("setApiUri"),
    setClientUri: createName("setClientUri"),
    setVersion: createName("setVersion"),
    setTheme: createName("setTheme"),
    setLanguage: createName("setLanguage"),
  },
  mutations: {
    setKeycloakUrl: createName("setKeycloakUrl"),
    setKeycloakClientId: createName("setKeycloakClientId"),
    setKeycloakRealm: createName("setKeycloakRealm"),
    setApiUri: createName("setApiUri"),
    setClientUri: createName("setClientUri"),
    setVersion: createName("setVersion"),
    setTheme: createName("setTheme"),
    setLanguage: createName("setLanguage"),
  },
  getters: {
    getKeycloakUrl: createName("getKeycloakUrl"),
    getKeycloakClientId: createName("getKeycloakClientId"),
    getKeycloakRealm: createName("getKeycloakRealm"),
    getApiUri: createName("getApiUri"),
    getClientUri: createName("getClientUri"),
    getVersion: createName("getVersion"),
    getTheme: createName("getTheme"),
    getLanguage: createName("getLanguage"),
  },
};
export const ConfigModule: Module<ConfigState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
