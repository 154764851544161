
import Vue from "vue";
import { TimatoForm } from "@/ui/components/common";
import { CaseDTO } from "@/common/dto";
import { NamedRoutes } from "@/ui/router";
import { Action } from "@/common/enums";
import moment from "moment";
import { CasesHttpClient } from "@/common/http";
import i18n from "@/ui/i18n";
import { BuefyHelpers, DtoHelpers } from "@/common/helpers";

export default Vue.extend({
  components: { TimatoForm },
  data() {
    return {
      caseForm: {} as CaseDTO,
      errors: [],
      loaded: false,
      saving: false,
      Action,
    };
  },
  computed: {
    caseId() {
      return this.$route.params.caseId;
    },
    title() {
      return DtoHelpers.generate_case_title(this.$data.caseForm);
    },
  },
  async created() {
    window.scrollTo(0, 0);
    await new CasesHttpClient()
      .loadCase(this.caseId)
      .then((res) => {
        this.caseForm = res;
        this.caseForm.accidentDate = moment(
          this.caseForm.accidentDate
        ).toDate();
        this.caseForm.repairDate = moment(this.caseForm.repairDate).toDate();
        this.loaded = true;
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.cases.loading"),
          err.message
        );
      });
  },
  methods: {
    saveCase: function () {
      this.saving = true;
      new CasesHttpClient()
        .updateCase(this.caseForm)
        .then((res) => {
          this.$router.push({
            name: NamedRoutes.DETAIL,
            params: { caseId: res.id },
          });
        })
        .catch((err) => {
          this.saving = false;
          if (
            err.response &&
            err.response.status === 400 &&
            err.response.data &&
            err.response.data.message.includes("Case was already modified")
          ) {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.cases.update.default"),
              i18n.t("UI.error.cases.update.case_modified")
            );
          } else {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.cases.update.default"),
              err.message
            );
          }
        });
    },
    cancel: function () {
      this.$router.push({
        name: NamedRoutes.DETAIL,
        params: { caseId: this.caseId },
      });
    },
  },
});
