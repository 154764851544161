import { FilterField } from "@/common/enums";

export class FilterDTO {
  filterField: FilterField;
  stringValue: string | undefined;
  dateValue: Date[] | undefined;

  constructor(
    filterField: FilterField,
    stringValue: string | undefined,
    dateValue: Date[] | undefined
  ) {
    this.filterField = filterField;
    this.stringValue = stringValue;
    this.dateValue = dateValue;
  }

  static empty_filter_dto(): FilterDTO {
    return new FilterDTO(FilterField.NONE, undefined, undefined);
  }
}
