
import NavBar from "@/ui/components/NavBar.vue";
import Vue from "vue";
import Buefy from "buefy";
import { mapGetters } from "vuex";
import { configModuleMeta } from "@/ui/store/configuration";
import { DataHttpClient } from "@/common/http";
import i18n from "@/ui/i18n";

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
});

export default Vue.extend({
  name: "Home",
  components: {
    NavBar,
  },
  data() {
    return {
      scroll_pos: 0,
      scroll_timer: 0,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.handle_scroll);
    await new DataHttpClient().checkHeartbeat().catch(() => {
      this.$buefy.dialog.alert({
        title: i18n.t("UI.error.general.title"),
        message: i18n.t("UI.error.server_na.message"),
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-triangle",
        ariaRole: "alertdialog",
        ariaModal: true,
        confirmText: i18n.t("UI.error.server_na.confim"),
        onConfirm: () => {
          localStorage.clear();
          this.$keycloak.logoutFn();
        },
      });
    });
  },
  methods: {
    handle_scroll: function () {
      if (this.scroll_timer) {
        return;
      }
      this.scroll_timer = setTimeout(() => {
        this.scroll_pos = window.scrollY;
        clearTimeout(this.scroll_timer);
        this.scroll_timer = 0;
      }, 100);
    },
    back_to_top: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapGetters({
      version: configModuleMeta.getters.getVersion,
      theme: configModuleMeta.getters.getTheme,
    }),
  },
});
