
import Vue from "vue";
import { LocationDTO, UserDetails } from "@/common/dto";
import i18n from "@/ui/i18n";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";

export default Vue.extend({
  props: {
    location: {
      type: String,
      default: "",
    },
  },
  methods: {
    compute_name(item: LocationDTO) {
      const prefix = this.isCaseHandler ? item.partner + ": " : "";
      return prefix + item.name;
    },
  },
  data() {
    return {
      items: [] as LocationDTO[],
    };
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isCaseHandler: function (): boolean {
      return (
        UserDetails.isAdminLocation(this.details) ||
        UserDetails.isCaseHandler(this.details)
      );
    },
    name(): string {
      const result = this.items.find((obj) => {
        return obj.locationCode === this.location;
      });

      return result !== undefined
        ? this.compute_name(result)
        : i18n.t("UI.message.notAvailable");
    },
  },
  async created() {
    await new DataHttpClient()
      .loadPhysicalLocations()
      .then((res) => (this.items = res))
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.location"),
          err.message
        );
      });
  },
});
