
import Vue, { PropType } from "vue";
import { UserActiveStatus } from "@/common/enums";
import i18n from "@/ui/i18n";

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: String as PropType<UserActiveStatus>,
      default: UserActiveStatus.NONE,
    },
  },
  data() {
    return {};
  },
  computed: {
    translation_key(): string {
      if (
        !i18n.te("UI.enum.user_active." + this.value) ||
        this.value === UserActiveStatus.NONE
      ) {
        return "UI.message.notAvailable";
      }
      return "UI.enum.user_active." + this.value;
    },
    css_class(): string {
      if (this.value === UserActiveStatus.INACTIVE) {
        return "is-warning";
      } else if (this.value === UserActiveStatus.DISABLED) {
        return "is-danger";
      } else if (this.value === UserActiveStatus.ACTIVE) {
        return "is-success";
      }
      return "";
    },
  },
});
