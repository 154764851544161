import { BaseClient } from "@/common/http/BaseClient";
import { LocationDTO, UserDTO } from "@/common/dto";

export class AdminHttpClient extends BaseClient {
  private readonly loadAllUsersPath = "/users/get/all";
  private readonly syncKeycloakPath = "/users/update";
  private readonly loadAllCaseHandlerPath = "/case-handler/get/all";
  private readonly addLocationsToCaseHandlerPath = "/j-lawyer/add-locations";
  private readonly addInsurancesToCaseHandlerPath = "/j-lawyer/add-insurances";

  constructor() {
    super("/admin");
  }

  async loadAllUsers(): Promise<UserDTO[]> {
    const url = this.url(this.loadAllUsersPath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }

  async syncKeycloak(): Promise<UserDTO[]> {
    const url = this.url(this.syncKeycloakPath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }

  async loadAllCaseHandlers(): Promise<LocationDTO[]> {
    const url = this.url(this.loadAllCaseHandlerPath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }

  async addLocationsToCaseHandler(caseHandler: string): Promise<boolean> {
    const url = this.urlWithId(this.addLocationsToCaseHandlerPath, caseHandler);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }

  async addInsurancesToCaseHandler(caseHandler: string): Promise<boolean> {
    const url = this.urlWithId(
      this.addInsurancesToCaseHandlerPath,
      caseHandler
    );
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }
}
