<template>
  <div class="mb-5">
    <label class="label">{{ label }}</label>
    <div class="columns is-vcentered">
      <div
        v-if="show_advance"
        class="column is-one-fifth-desktop has-text-centered"
      >
        <label class="label has-text-success">{{
          $t("UI.detail.advanceReceived")
        }}</label>
      </div>
      <div v-else class="column is-one-fifth-desktop has-text-centered">
        <b-icon :icon="icon" :type="rating" size="is-large" />
        <p>{{ percentage | percentage }}</p>
      </div>
      <div class="column">
        <label class="label">{{ $t("UI.detail.issued") }}</label>
        <p>{{ issued | currency }}</p>
      </div>
      <div class="column">
        <label class="label">{{ $t("UI.detail.received") }}</label>
        <p>{{ received | currency }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Evaluation",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    issued: {
      type: Number,
      required: false,
      default: 0,
    },
    received: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    percentage: function () {
      return (this.received / this.issued) * 100;
    },
    show_advance: function () {
      return this.issued < 1 && this.received > 1;
    },
    rating: function () {
      if (this.percentage > 90) {
        return "is-success";
      } else if (this.percentage > 60) {
        return "is-warning";
      } else {
        return "is-danger";
      }
    },
    icon: function () {
      if (this.rating === "is-success") {
        return "caret-square-up";
      } else if (this.rating === "is-warning") {
        return "caret-square-right";
      } else {
        return "caret-square-down";
      }
    },
  },
});
</script>
