
import Vue, { PropType } from "vue";
import { RepairDecision } from "@/common/enums";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import i18n from "@/ui/i18n";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: String as PropType<RepairDecision>,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  methods: {},
  data() {
    return {
      RepairDecision,
    };
  },
  computed: {
    translationKey(): string {
      if (
        !i18n.te("UI.enum.repair_wanted." + this.value) ||
        this.value === RepairDecision.NONE
      ) {
        return "UI.message.notAvailable";
      }
      return "UI.enum.repair_wanted." + this.value;
    },
    localValue: {
      get: function (): string {
        if ((this.value as string) === "") {
          return RepairDecision.NONE;
        }
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
      },
    },
  },
});
