enum LocalStorageConstants {
  NONE = "",
  EMAIL_ADDRESSEE = "email_addressee",
  LOCATION_FILTER = "location_filter",
  CASE_HANDLER_FILTER = "case_handler_filter",
  REPAIR_DECISION_FILTER = "repair_decision_filter",
  STATUS_FILTER = "status_filter",
  LICENSE_PLATE_FILTER = "license_plate_filter",
  FILE_SIGN_FILTER = "file_sign_filter",
  CUSTOMER_NAME_FILTER = "customer_name_filter",
  CREATED_FILTER = "created_filter",
  UPDATED_FILTER = "updated_filter",
  CLOSED_FILTER = "closed_filter",
  PRECONFIGURED_FILTER = "preconfigured_filter",
  DAMAGE_TYPE_FILTER = "damage_type_filter",
  EXPERT_REFERENCE_FILTER = "expert_reference_filter",
}

export default LocalStorageConstants;
