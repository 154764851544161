
import Vue, { PropType } from "vue";
import { AddressDTO } from "@/common/dto";
import BaseInput from "@/ui/components/common/forms/BaseInput.vue";
import TitleSelect from "@/ui/components/common/forms/TitleSelect.vue";
import CountrySelect from "@/ui/components/common/forms/CountrySelect.vue";
import { Title } from "@/common/enums";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: { CountrySelect, TitleSelect, BaseInput },
  model: {
    prop: "address",
    event: "valueChanged",
  },
  props: {
    address: {
      type: Object as PropType<AddressDTO>,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mailTooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      Title,
    };
  },
  computed: {
    localAddress: {
      get: function (): AddressDTO {
        return this.address;
      },
      set: function (value: AddressDTO) {
        this.$emit("valueChanged", value);
      },
    },
  },
});
