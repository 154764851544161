
import Vue, { PropType } from "vue";
import { SimpleUserDTO } from "@/common/dto";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { BaseLabel } from "@/ui/components/common/forms";

export default Vue.extend({
  name: "NotificationSelection",
  components: { BaseLabel },
  props: {
    selectedColleagues: {
      type: Array as PropType<SimpleUserDTO[]>,
    },
  },
  data() {
    return {
      filter: "",
      colleagues: [] as SimpleUserDTO[],
    };
  },
  computed: {
    label(): string {
      return this.$i18n.t("UI.detail.notifyColleague");
    },
    locallySelectedColleagues: {
      get() {
        return this.selectedColleagues;
      },
      set(value) {
        this.$emit("selectedColleaguesChange", value);
      },
    },
    tooltip(): string {
      return this.$i18n.t("UI.tooltip.notifyColleague");
    },
    filteredColleagues(): SimpleUserDTO[] {
      return this.colleagues.filter((colleague: SimpleUserDTO) => {
        return (
          !this.selectedColleagues.some(
            (c) => c.username === colleague.username
          ) &&
          (colleague.fullName
            .toString()
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >= 0 ||
            colleague.partnerLocation
              .toString()
              .toLowerCase()
              .indexOf(this.filter.toLowerCase()) >= 0)
        );
      });
    },
  },
  mounted() {
    new DataHttpClient()
      .allColleagues()
      .then((data) => {
        this.colleagues = data;
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.colleagues"),
          err.message
        );
      });
  },
  methods: {
    colleaguesChanged(selectedColleagues: SimpleUserDTO[]) {
      this.locallySelectedColleagues = selectedColleagues;
    },
    filterChanged(text: string) {
      this.filter = text;
    },
  },
});
