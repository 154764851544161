import { CaseDTO } from "@/common/dto";
import { BaseClient } from "@/common/http/BaseClient";
import { Status } from "@/common/enums";

export class CasesHttpClient extends BaseClient {
  private readonly loadPath = "/get";
  private readonly createPath = "/put/new";
  private readonly updatePath = "/put/update";
  private readonly statusUpdatePath = "/put/status-update";

  constructor() {
    super("/v1/cases");
  }

  async loadCase(id: string): Promise<CaseDTO> {
    const url = this.urlWithId(this.loadPath, id);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async createCase(aCase: CaseDTO): Promise<CaseDTO> {
    const url = this.url(this.createPath);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = aCase;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async updateCase(aCase: CaseDTO | null): Promise<CaseDTO> {
    const url = this.url(this.updatePath);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = aCase;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async updateStatus(id: string, status: Status): Promise<boolean> {
    const url = this.urlWithId(this.statusUpdatePath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.params = { status: status };

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
