
import Vue from "vue";
import { LegalInsuranceDTO } from "@/common/dto";
import i18n from "@/ui/i18n";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";

export default Vue.extend({
  components: {},
  props: {
    insuranceId: {
      type: String,
      default: "",
    },
  },
  methods: {},
  data() {
    return {
      items: [] as LegalInsuranceDTO[],
    };
  },
  computed: {
    name(): string {
      const result = this.items.find((obj) => {
        return obj.legalInsuranceId === this.insuranceId;
      });

      return result !== undefined
        ? result.name
        : i18n.t("UI.enum.general.OTHER");
    },
  },
  async created() {
    await new DataHttpClient()
      .loadLegalInsurances()
      .then((res) => (this.items = res))
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.legalInsurance"),
          err.message
        );
      });
  },
});
