import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/ui/store";
import { UserDetails } from "@/common/dto";

export interface UserState {
  details: UserDetails;
}

export const state: UserState = {
  details: UserDetails.empty(),
};

export const actions: ActionTree<UserState, RootState> = {
  setDetails({ commit }, details: UserDetails) {
    commit("setDetails", details);
  },
};
export const mutations: MutationTree<UserState> = {
  setDetails(theState, details: UserDetails) {
    theState.details = details;
  },
};

export const getters: GetterTree<UserState, RootState> = {
  getDetails(theState): UserDetails {
    return theState.details;
  },
};

function createName(methodName: string): string {
  return "userModule" + "/" + methodName;
}

export const userModuleMeta = {
  actions: {
    setDetails: createName("setDetails"),
  },
  mutations: {
    setDetails: createName("setDetails"),
  },
  getters: {
    getDetails: createName("getDetails"),
  },
};
export const UserModule: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
