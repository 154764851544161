
import Vue, { PropType } from "vue";
import { DiffType } from "@/common/enums";
import { DiffDTO } from "@/common/dto";
import DiffRow from "@/ui/components/detail/history/DiffRow.vue";

export default Vue.extend({
  components: { DiffRow },
  props: {
    diffType: {
      type: String as PropType<DiffType>,
    },
    changes: {
      type: Object as PropType<Record<string, DiffDTO>>,
    },
  },
  methods: {},
  computed: {},
});
