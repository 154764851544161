enum NamedRoutes {
  MAIN = "main",
  APP = "app",
  DASHBOARD = "dashboard",
  OVERVIEW = "overview",
  CREATE = "create",
  DETAIL = "show",
  EDIT = "edit",
  ADMIN = "admin",
  ADMIN_USER_LIST = "admin_user_list",
  ADMIN_JL = "admin_jl",
}

export default NamedRoutes;
