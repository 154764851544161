
import Vue, { PropType } from "vue";
import { InsuranceDTO } from "@/common/dto";
import i18n from "@/ui/i18n";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import { TranslateResult } from "vue-i18n";

const OTHER_UUID = "7a29e3d4-ee7b-444d-a2a2-52249c36a0bd";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      items: [] as InsuranceDTO[],
      query: "",
      OTHER_UUID,
    };
  },
  computed: {
    localValue: {
      get: function (): string {
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
      },
    },
    filteredDataArray(): InsuranceDTO[] {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
    name(): string {
      const result = this.items.find((obj) => {
        return obj.insuranceId === this.localValue;
      });

      return result !== undefined
        ? result.name
        : this.localValue === OTHER_UUID
        ? i18n.t("UI.enum.general.OTHER")
        : i18n.t("UI.enum.general.UNKNOWN");
    },
  },
  methods: {
    compute_name(item: InsuranceDTO) {
      return item.name;
    },
    handleSelect(item: InsuranceDTO) {
      this.localValue = item.insuranceId;
    },
    handleInput(value: string) {
      this.query = value;
    },
    handleBlur() {
      if (!this.filteredDataArray.length) {
        this.query = "";
      }
    },
  },
  async created() {
    await new DataHttpClient()
      .loadInsurances()
      .then((res) => {
        this.items = res;
        this.items.push({
          insuranceId: OTHER_UUID,
          name: i18n.t("UI.enum.general.OTHER"),
        });
        const selectedInsurance = this.items.find(
          (item) => item.insuranceId === this.localValue
        );
        if (selectedInsurance) {
          this.query = selectedInsurance.name;
        }
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.insurance"),
          err.message
        );
      });
  },
});
