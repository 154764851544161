import { BaseClient } from "@/common/http/BaseClient";
import {
  EvaluationValuesDTO,
  InsuranceDTO,
  LegalInsuranceDTO,
  LocationDTO,
  SimpleUserDTO,
} from "@/common/dto";

export class DataHttpClient extends BaseClient {
  private readonly getInsurancesPath = "/insurance/get/all";
  private readonly getLegalInsurancesPath = "/legal-insurance/get/all";
  private readonly getLocationsPath = "/location/get/all";
  private readonly getPhysicalLocations = "/location/get/not-main";
  private readonly getCustomers = "/location/get/main";
  private readonly getEvalValues = "/analysis/eval-values";
  private readonly getLocationById = "/location/get/by-id";
  private readonly getHeartbeat = "/health";
  private readonly getColleagues = "/users/colleagues";
  private readonly getAllAppraisersUsers = "/users/appraisers/users";
  private readonly getAvailableAppraisers = "/users/appraisers/locations";

  constructor() {
    super("/v1/data");
  }

  async loadInsurances(): Promise<InsuranceDTO[]> {
    const url = this.url(this.getInsurancesPath);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadLegalInsurances(): Promise<LegalInsuranceDTO[]> {
    const url = this.url(this.getLegalInsurancesPath);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadLocations(): Promise<LocationDTO[]> {
    const url = this.url(this.getLocationsPath);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadPhysicalLocations(): Promise<LocationDTO[]> {
    const url = this.url(this.getPhysicalLocations);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadCustomers(): Promise<LocationDTO[]> {
    const url = this.url(this.getCustomers);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadEvalValues(case_id: string): Promise<EvaluationValuesDTO> {
    const url = `${this.urlWithId(this.getEvalValues, case_id)}`;
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async checkHeartbeat(): Promise<boolean> {
    const url = this.url(this.getHeartbeat);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async allColleagues(): Promise<SimpleUserDTO[]> {
    const url = this.url(this.getColleagues);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async allAppraisersUsers(code: string): Promise<SimpleUserDTO[]> {
    const url = `${this.urlWithId(this.getAllAppraisersUsers, code)}`;
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async availableAppraisers(code: string): Promise<LocationDTO[]> {
    const url = `${this.url(this.getAvailableAppraisers)}`;
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "POST";
    reqConfig.data = { code };

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async loadLocationById(id: string): Promise<LocationDTO> {
    const url = this.urlWithId(this.getLocationById, id);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
