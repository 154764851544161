import img_linkki from "@/ui/assets/logo_linkki.png";

export class Image {
  file: string;
  width: number;
  height: number;

  constructor(file: string, width: number, height: number) {
    this.file = file;
    this.width = width;
    this.height = height;
  }

  static default_image(): Image {
    return new Image(img_linkki, 177, 85);
  }
}
