import { LocationDTO } from "@/common/dto/LocationDTO";
import { LocationType, Roles } from "@/common/enums";

export class UserDetails {
  username: string;
  fullName: string;
  roles: Set<Roles>;
  locations: Set<LocationDTO>;

  constructor(
    username: string,
    fullName: string,
    roles: Set<Roles>,
    locations: Set<LocationDTO>
  ) {
    this.username = username;
    this.fullName = fullName;
    this.roles = roles;
    this.locations = locations;
  }

  static empty(): UserDetails {
    return new UserDetails("", "", new Set(), new Set());
  }

  static isAdmin(userDetails: UserDetails): boolean {
    return Array.from(userDetails.roles).some((role) => role === Roles.ADMIN);
  }

  static isAdminLocation(userDetails: UserDetails): boolean {
    return Array.from(userDetails.locations).some(
      (location) => location.type === LocationType.ADMIN
    );
  }

  static isCaseHandler(userDetails: UserDetails): boolean {
    return Array.from(userDetails.locations).some(
      (location) => location.type === LocationType.CASE_HANDLER
    );
  }

  static isMainLocation(userDetails: UserDetails): boolean {
    return Array.from(userDetails.locations).some(
      (location) => location.mainLocation
    );
  }

  static isAppraiser(userDetails: UserDetails): boolean {
    return Array.from(userDetails.locations).some(
      (location) => location.type === LocationType.APPRAISER
    );
  }
}
