import VueI18n from "vue-i18n";
import Messages from "./Messages";
import DateTimeFormat from "./DateTimeFormat";
import Vue from "vue";
import { configModuleMeta } from "@/ui/store/configuration";
import store from "@/ui/store";

export { Messages, DateTimeFormat };

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.getters[configModuleMeta.getters.getLanguage],
  messages: Messages,
});

export default i18n;
