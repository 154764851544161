import { BaseClient } from "@/common/http/BaseClient";

export class AnalyticsHttpClient extends BaseClient {
  private readonly loadAllAnalyticsPath = "/get/all";

  constructor() {
    super("/analytics");
  }

  async loadAllAnalytics(): Promise<object> {
    const url = this.url(this.loadAllAnalyticsPath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }
}
