
import Vue from "vue";
import { UserDTO } from "@/common/dto";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { AdminHttpClient } from "@/common/http";
import ActiveStatusSelect from "@/ui/components/admin/ActiveStatusSelect.vue";
import ActiveStatusTag from "@/ui/components/admin/ActiveStatusTag.vue";

export default Vue.extend({
  components: { ActiveStatusTag, ActiveStatusSelect },
  data() {
    return {
      loaded: false,
      inactive_users: [] as UserDTO[],
      columns: [
        {
          field: "status",
          label: i18n.t("UI.admin.userList.status"),
          searchable: true,
          sortable: true,
          active_status: true,
          width: "100",
          // eslint-disable-next-line
            customSearch: (row: any, input: string): boolean => {
            return input && row.status && row.status === input;
          },
        },
        {
          field: "fullName",
          label: i18n.t("UI.admin.userList.fullName"),
          searchable: true,
          sortable: true,
        },
        {
          field: "mail",
          label: i18n.t("UI.admin.userList.email"),
          searchable: true,
          sortable: true,
        },
        {
          field: "partner",
          label: i18n.t("UI.admin.userList.partner"),
          searchable: true,
          sortable: true,
        },
        {
          field: "location",
          label: i18n.t("UI.admin.userList.location"),
          searchable: true,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    syncKeycloak() {
      this.loaded = false;
      new AdminHttpClient()
        .syncKeycloak()
        .then((res) => {
          this.inactive_users = res;
          this.loaded = true;
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.cases.loading"),
            err.message
          );
        });
    },
  },
  computed: {},
  async created() {
    window.scrollTo(0, 0);
    await new AdminHttpClient()
      .loadAllUsers()
      .then((res) => {
        this.inactive_users = res;
        this.loaded = true;
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.cases.loading"),
          err.message
        );
      });
  },
});
