import VueRouter, { Route, RouteConfig } from "vue-router";
import NamedRoutes from "./NamedRoutes";
import Dashboard from "@/ui/views/Dashboard.vue";
import Overview from "@/ui/views/Overview.vue";
import Create from "@/ui/views/Create.vue";
import Detail from "@/ui/views/Detail.vue";
import Vue from "vue";
import Edit from "@/ui/views/Edit.vue";
import i18n from "@/ui/i18n";
import Admin from "@/ui/views/Admin.vue";
import UserList from "@/ui/components/admin/UserList.vue";
import JLawyer from "@/ui/components/admin/JLawyer.vue";

export { NamedRoutes };

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: NamedRoutes.MAIN,
    redirect: {
      name: NamedRoutes.DASHBOARD,
    },
  },
  {
    path: "/dashboard",
    name: NamedRoutes.DASHBOARD,
    components: {
      MainContent: Dashboard,
    },
    meta: {
      title: i18n.t("UI.title.Dashboard"),
    },
  },
  {
    path: "/overview",
    name: NamedRoutes.OVERVIEW,
    components: {
      MainContent: Overview,
    },
    meta: {
      title: i18n.t("UI.title.CaseOverview"),
    },
  },
  {
    path: "/create",
    name: NamedRoutes.CREATE,
    components: {
      MainContent: Create,
    },
    meta: {
      title: i18n.t("UI.title.CaseCreate"),
    },
  },
  {
    path: "/show/:caseId",
    name: NamedRoutes.DETAIL,
    components: {
      MainContent: Detail,
    },
    meta: {
      title: i18n.t("UI.title.Detail"),
    },
    props: true,
  },
  {
    path: "/edit/:caseId",
    name: NamedRoutes.EDIT,
    components: {
      MainContent: Edit,
    },
    meta: {
      title: i18n.t("UI.title.Edit"),
    },
    props: true,
  },
  {
    path: "/admin",
    name: NamedRoutes.ADMIN,
    components: {
      MainContent: Admin,
    },
    redirect: {
      name: NamedRoutes.ADMIN_USER_LIST,
    },
    children: [
      {
        path: "user-list",
        name: NamedRoutes.ADMIN_USER_LIST,
        components: {
          AdminContent: UserList,
        },
        meta: {
          title: i18n.t("UI.title.AdminUserList"),
        },
      },
      {
        path: "j-lawyer",
        name: NamedRoutes.ADMIN_JL,
        components: {
          AdminContent: JLawyer,
        },
        meta: {
          title: i18n.t("UI.title.AdminUserList"),
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.afterEach((to: Route) => {
  Vue.nextTick(() => {
    if (to !== undefined && to.meta !== undefined && to.meta.title !== "") {
      document.title = to.meta.title;
    } else {
      document.title = i18n.t("UI.title.prefix");
    }
  });
});

export default router;
