export class EvaluationValuesDTO {
  issueDate: Date | null;

  repairInvoiceNo: string;
  repairCostsIssued: number;
  repairCostsReceived: number;

  rentalInvoiceNo: string;
  rentalCostsIssued: number;
  rentalCostsReceived: number;

  substitutionNoticeDate: Date | null;
  advanceRequestDate: Date | null;

  constructor(
    issueDate: Date | null,
    repairInvoiceNo: string,
    repairCostsIssued: number,
    repairCostsReceived: number,
    rentalInvoiceNo: string,
    rentalCostsIssued: number,
    rentalCostsReceived: number,
    substitutionNoticeDate: Date | null,
    advanceRequestDate: Date | null
  ) {
    this.issueDate = issueDate;

    this.repairInvoiceNo = repairInvoiceNo;
    this.repairCostsIssued = repairCostsIssued;
    this.repairCostsReceived = repairCostsReceived;

    this.rentalInvoiceNo = rentalInvoiceNo;
    this.rentalCostsIssued = rentalCostsIssued;
    this.rentalCostsReceived = rentalCostsReceived;
    this.substitutionNoticeDate = substitutionNoticeDate;
    this.advanceRequestDate = advanceRequestDate;
  }

  static empty_values(): EvaluationValuesDTO {
    return new EvaluationValuesDTO(null, "", 0, 0, "", 0, 0, null, null);
  }
}
