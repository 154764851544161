enum FilterField {
  NONE = "NONE",
  STATUS = "STATUS",
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  CLOSED = "CLOSED",
  LOCATION = "LOCATION",
  REPAIR_WANTED = "REPAIR_WANTED",
  CUSTOMER_NAME = "CUSTOMER_NAME",
  LICENSE_PLATE = "LICENSE_PLATE",
  PRECONFIGURED = "PRECONFIGURED",
  FILE_SIGN = "FILE_SIGN",
  CASE_HANDLER = "CASE_HANDLER",
  DAMAGE_TYPE = "DAMAGE_TYPE",
  EXPERT_REFERENCE = "EXPERT_REFERENCE",
}

export default FilterField;
