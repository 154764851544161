
import Vue from "vue";
import { event_bus } from "@/main";
import { PER_PAGE_CHANGED } from "@/common/events";

export default Vue.extend({
  model: {
    prop: "page",
    event: "pageChanged",
  },
  props: {
    page: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  computed: {
    localPage: {
      get: function (): number {
        return this.page;
      },
      set: function (value: number) {
        this.$emit("pageChanged", value);
      },
    },
    localPerPage: {
      get: function (): number {
        return this.perPage;
      },
      set: function (value: number) {
        event_bus.$emit(PER_PAGE_CHANGED, value);
      },
    },
  },
});
