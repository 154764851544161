import { Image, Theme } from "@/common/dto";
import img_linkki from "@/ui/assets/logo_linkki.png";
import img_bebion from "@/ui/assets/logo_bebion.svg";
import img_bechtel from "@/ui/assets/logo_bechtel.png";
import img_canestrini from "@/ui/assets/logo_canestrini.png";
import img_geyer from "@/ui/assets/logo_geyer.png";
import img_hermansky from "@/ui/assets/logo_hermansky.png";
import img_hh_ssd from "@/ui/assets/logo_hh-ssd.png";
import img_jacobs from "@/ui/assets/logo_jacobs.png";
import img_klaiber from "@/ui/assets/logo_klaiber.png";
import img_kohler from "@/ui/assets/logo_kohler.png";
import img_timato from "@/ui/assets/logo_timato.png";
import img_tsw from "@/ui/assets/logo_tsw.svg";
import img_raiffeisen from "@/ui/assets/logo_raiffeisen.png";
import img_rastetter from "@/ui/assets/logo_rastetter.png";
import img_schmid from "@/ui/assets/logo_schmid.svg";
import img_autostueble from "@/ui/assets/logo_autostueble.png";
import img_gursch from "@/ui/assets/logo_gursch.png";
import img_gottfriedschultz from "@/ui/assets/logo_gottfriedschultz.png";
import img_gurschah from "@/ui/assets/logo_gurschah.png";
import img_zeisler from "@/ui/assets/logo_zeisler.svg";
import img_ziefleunger from "@/ui/assets/logo_ziefleunger.svg";
import img_ziefleungerah from "@/ui/assets/logo_ziefleungerah.png";
import img_loehr from "@/ui/assets/logo_loehr.png";
import { ColorUpdater } from "bulma-css-vars";
import { bulmaCssVariablesDefs } from "@/ui/scss/bulma-generated/bulma-colors";

class ThemeSwitcher {
  private readonly alias = new Map([
    ["lossburg", "ziefleunger"],
    ["bader", "ziefleunger"],
  ]);
  private readonly themes = new Map([
    [
      "linkki",
      new Theme(
        new Image(img_linkki, 177, 85),
        "#964091",
        "#dfdfdf",
        "#dfdfdf",
        "#ffffff"
      ),
    ],
    [
      "bebion",
      new Theme(
        new Image(img_bebion, 177, 85),
        "#567eb6",
        "#dfdfdf",
        "#042148",
        "#ffffff"
      ),
    ],
    [
      "hermansky",
      new Theme(
        new Image(img_hermansky, 200, 50),
        "#fadd29",
        "#484848",
        "#fff",
        "#f1f1f1"
      ),
    ],
    [
      "klaiber",
      new Theme(
        new Image(img_klaiber, 263, 75),
        "#0a2c4a",
        "#dfdfdf",
        "#dfdfdf",
        "#ffffff"
      ),
    ],
    [
      "kohler",
      new Theme(
        new Image(img_kohler, 200, 110),
        "#de0e37",
        "#dfdfdf",
        "#dfdfdf",
        "#ffffff"
      ),
    ],
    [
      "timato",
      new Theme(
        new Image(img_timato, 93, 80),
        "#b1cb16",
        "#eae4d4",
        "#eae4d4",
        "#ffffff"
      ),
    ],
    [
      "rastetter",
      new Theme(
        new Image(img_rastetter, 200, 80),
        "#fea40c",
        "#f1f1f1",
        "#f1f1f1",
        "#fff"
      ),
    ],
    [
      "raiffeisen",
      new Theme(
        new Image(img_raiffeisen, 227, 80),
        "#ee840b",
        "#f1f1f1",
        "#f1f1f1",
        "#ffffff"
      ),
    ],
    [
      "jacobs",
      new Theme(
        new Image(img_jacobs, 331, 90),
        "#D20824",
        "#272c31",
        "#272c31",
        "#ffffff"
      ),
    ],
    [
      "autostueble",
      new Theme(
        new Image(img_autostueble, 190, 110),
        "#173285",
        "#EBEBEB",
        "#EBEBEB",
        "#ffffff"
      ),
    ],
    [
      "bechtel",
      new Theme(
        new Image(img_bechtel, 190, 110),
        "#c10f22",
        "#EBEBEB",
        "#EBEBEB",
        "#ffffff"
      ),
    ],
    [
      "gursch",
      new Theme(
        new Image(img_gursch, 190, 110),
        "#cb622c",
        "#444242",
        "#444242",
        "#ffffff"
      ),
    ],
    [
      "gurschah",
      new Theme(
        new Image(img_gurschah, 190, 110),
        "#349dd3",
        "#c2c2c2",
        "#c2c2c2",
        "#ffffff"
      ),
    ],
    [
      "ziefleunger",
      new Theme(
        new Image(img_ziefleunger, 190, 110),
        "#5e3395",
        "#dadada",
        "#dadada",
        "#ffffff"
      ),
    ],
    [
      "ziefleungerah",
      new Theme(
        new Image(img_ziefleungerah, 190, 110),
        "#a1cc6a",
        "#797979",
        "#797979",
        "#ffffff"
      ),
    ],
    [
      "schmid",
      new Theme(
        new Image(img_schmid, 190, 110),
        "#1997e6",
        "#EBEBEB",
        "#000000",
        "#fff"
      ),
    ],
    [
      "zeisler",
      new Theme(
        new Image(img_zeisler, 190, 110),
        "#1D69D4",
        "#EBEBEB",
        "#000000",
        "#fff"
      ),
    ],
    [
      "loehr",
      new Theme(
        new Image(img_loehr, 190, 110),
        "#299ab8",
        "#EBEBEB",
        "#f2f2f2",
        "#fff"
      ),
    ],
    [
      "gottfriedschultz",
      new Theme(
        new Image(img_gottfriedschultz, 190, 110),
        "#274669",
        "#EBEBEB",
        "#f2f2f2",
        "#fff"
      ),
    ],
    [
      "tsw",
      new Theme(
        new Image(img_tsw, 397, 99),
        "#026eb2",
        "#c8c8c8",
        "#d8dcdf",
        "#fff"
      ),
    ],
    [
      "geyer",
      new Theme(
        new Image(img_geyer, 253, 81),
        "#ffd500",
        "#eeeeee",
        "#d8dcdf",
        "#fff"
      ),
    ],
    [
      "canestrini",
      new Theme(
        new Image(img_canestrini, 316, 100),
        "#cba403",
        "#eeeeee",
        "#d8dcdf",
        "#fff"
      ),
    ],
    [
      "hh-ssd",
      new Theme(
        new Image(img_hh_ssd, 142, 100),
        "#0233dd",
        "#7490b4",
        "#d8dcdf",
        "#fff"
      ),
    ],
  ]);

  private readonly colorUpdater = new ColorUpdater(bulmaCssVariablesDefs);

  switchTheme(themeName: string): void {
    const theme = this.getTheme(themeName);

    this.updateColors(theme);
  }

  updateColors(theme: Theme): void {
    this.colorUpdater.updateVarsInDocument("primary", theme.primary);
    this.colorUpdater.updateVarsInDocument("dark", theme.dark);
    this.colorUpdater.updateVarsInDocument("light", theme.light);
    document.documentElement.style.setProperty("--view", theme.view);
  }

  getTheme(themeName: string): Theme {
    const alias = this.alias.get(themeName);
    if (alias !== undefined) {
      themeName = alias;
    }
    const theme = this.themes.get(themeName);
    if (theme !== undefined) {
      return theme;
    }
    return this.themes.get("linkki") as Theme;
  }

  isThemeAvailable(themeName: string): boolean {
    return this.themes.get(themeName) !== undefined;
  }

  getAvailableThemes(): Array<string> {
    return Array.from(this.themes.keys()).sort();
  }
}

const theme_switcher = new ThemeSwitcher();
export default theme_switcher;
