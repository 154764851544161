
import NotificationSelection from "@/ui/components/detail/comments/NotificationSelection.vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import Avatar from "vue-avatar";
import { NewCommentEvent } from "@/common/types";
import { SimpleUserDTO } from "@/common/dto";

export default Vue.extend({
  name: "ComposeComment",
  components: { NotificationSelection, Avatar },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
  },
  data() {
    return {
      comment: "",
      rows: 1,
      notifiedUsers: [] as SimpleUserDTO[],
    };
  },
  methods: {
    focusText(): void {
      this.rows = 5;
    },
    focusoutText(): void {
      if (this.comment.trim() === "") {
        this.rows = 1;
      }
    },
    save(): void {
      const { comment, notifiedUsers } = this;
      this.$emit("newComment", { comment, notifiedUsers } as NewCommentEvent);
    },
    reset() {
      this.comment = "";
      this.notifiedUsers = [];
    },
    updateNotifiedUsers(users: SimpleUserDTO[]) {
      this.notifiedUsers = users;
    },
  },
});
