
import Vue from "vue";
import { mapGetters } from "vuex";
import { NamedRoutes } from "@/ui/router";
import { userModuleMeta } from "@/ui/store/user";
import i18n from "@/ui/i18n";
import Avatar from "vue-avatar";
import { Image, LocationDTO, UserDetails } from "@/common/dto";
import { configModuleMeta } from "@/ui/store/configuration";
import theme_switcher from "@/common/service/ThemeSwitcher";
import { MagicButton } from "@/ui/components/common";

export default Vue.extend({
  components: { MagicButton, Avatar },
  data() {
    return { NamedRoutes };
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
      theme: configModuleMeta.getters.getTheme,
    }),
    isAdmin: function (): boolean {
      return UserDetails.isAdminLocation(this.details);
    },
    isDashboard(): boolean {
      return NamedRoutes.DASHBOARD === this.$route.name;
    },
    isOverview(): boolean {
      return NamedRoutes.OVERVIEW === this.$route.name;
    },
    isCreate(): boolean {
      return NamedRoutes.CREATE === this.$route.name;
    },
    isDashboardAllowed(): boolean {
      return !UserDetails.isAppraiser(this.details);
    },
    isAdminRoute(): boolean {
      if (typeof this.$route.name === "string") {
        return this.$route.name.startsWith(NamedRoutes.ADMIN);
      }
      return false;
    },
    locationName(): string {
      if (UserDetails.isMainLocation(this.details)) {
        return i18n.t("UI.nav.allLocations");
      } else {
        return this.details.locations
          .map((location: LocationDTO) => location.name)
          .join(", ");
      }
    },
    image(): Image {
      return theme_switcher.getTheme(this.theme).image;
    },
  },
  methods: {
    logout(): void {
      localStorage.clear();
      this.$keycloak.logoutFn();
    },
  },
});
