
import Vue, { PropType } from "vue";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { TranslateResult } from "vue-i18n";
import { validateIBAN } from "ibantools";
import i18n from "@/ui/i18n";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: [String],
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      ibanError: "",
    };
  },
  methods: {
    stringValue: function (str: string) {
      return str + "";
    },
    validateIBAN(newValue?: string) {
      if (newValue === undefined || newValue.trim() === "") {
        this.ibanError = "";
      } else {
        const iban = newValue.replace(/\s/g, "");
        const validationResult = validateIBAN(iban);
        if (!validationResult.valid) {
          this.ibanError = validationResult.errorCodes
            .map((e) => i18n.t(`UI.error.iban.${e}`))
            .join(", ");
        } else {
          this.ibanError = "";
        }
      }
    },
  },
  watch: {
    localValue: function (newValue: string) {
      this.validateIBAN(newValue);
    },
  },
  computed: {
    localValue: {
      get: function (): string | undefined {
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
        this.validateIBAN();
      },
    },
  },
});
