
import Vue, { PropType } from "vue";
import { CommentDTO, SimpleUserDTO } from "@/common/dto";
import moment from "moment/moment";
import { BaseTextarea } from "@/ui/components/common/forms";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import Avatar from "vue-avatar";
import NotificationSelection from "@/ui/components/detail/comments/NotificationSelection.vue";

export default Vue.extend({
  name: "Comment",
  components: { BaseTextarea, NotificationSelection, Avatar },
  props: {
    comment: {
      type: Object as PropType<CommentDTO>,
    },
  },
  data() {
    return {
      editEnabled: false,
      editedComment: new CommentDTO(""),
      moment,
    };
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    notifiedUsersAsString(): string {
      return this.comment.notifyUser
        .map((u) => u.fullName)
        .join(", ")
        .trim();
    },
    fullName(): string {
      return this.comment?.fullName ? this.comment.fullName : "Max Mustermann";
    },
  },
  methods: {
    startEdit() {
      this.editedComment = { ...this.comment };
      this.editEnabled = true;
    },
    endEdit() {
      this.editEnabled = false;
      this.editedComment = new CommentDTO("");
    },
    saveEdit() {
      this.$emit("updateComment", this.editedComment);
      this.endEdit();
    },
    updateNotifiedUsers(users: SimpleUserDTO[]) {
      this.editedComment.notifyUser = users;
    },
  },
});
