
import Vue, { PropType } from "vue";
import { Country } from "@/common/enums";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import i18n from "@/ui/i18n";
import { TranslateResult } from "vue-i18n/types";

class CountryWithTranslations {
  key: string;
  translation: string;

  constructor(key: string, translation: string) {
    this.key = key;
    this.translation = translation;
  }
}

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: String as PropType<Country>,
      default: Country.NONE,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      Country,
      entries: [] as CountryWithTranslations[],
      query: i18n.t("UI.enum.country." + this.value),
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit("valueChanged", value);
      },
    },
    translationKey(): string {
      if (
        !i18n.te("UI.enum.country." + this.value) ||
        this.value === Country.NONE
      ) {
        return "UI.message.notAvailable";
      }
      return "UI.enum.country." + this.value;
    },
    filteredDataArray(): CountryWithTranslations[] {
      return this.entries.filter((item) => {
        return item.translation
          .toLowerCase()
          .includes(this.query.toLowerCase());
      });
    },
  },
  methods: {
    displayName(item: CountryWithTranslations) {
      return item.translation;
    },
    handleSelect(item: CountryWithTranslations) {
      this.localValue = item.key;
    },
    handleInput(value: string) {
      this.query = value;
    },
    handleBlur() {
      if (!this.filteredDataArray.length) {
        this.query = "";
      }
    },
  },
  created() {
    if (!this.disabled) {
      Object.keys(Country).forEach((c) => {
        if (c !== Country.NONE) {
          this.entries.push(
            new CountryWithTranslations(c, i18n.t("UI.enum.country." + c))
          );
        }
      });

      this.entries.sort((o1, o2) =>
        o1.translation.localeCompare(o2.translation)
      );
    }
  },
});
