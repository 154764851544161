
import Vue from "vue";
import { event_bus } from "@/main";
import { REPAIR_DATE_MISSING_ADDED } from "@/common/events";

export default Vue.extend({
  components: {},
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    event: {
      type: String,
      required: true,
    },
  },
  computed: {
    localValue: {
      get: function (): boolean {
        return this.value;
      },
      set: function (value: boolean) {
        this.$emit("valueChanged", value);
        if (value) {
          event_bus.$emit(REPAIR_DATE_MISSING_ADDED);
        }
      },
    },
  },
  methods: {
    toggle() {
      this.localValue = !this.localValue;
    },
  },
});
