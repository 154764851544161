
import Vue from "vue";
import { userModuleMeta } from "@/ui/store/user";
import { mapGetters } from "vuex";
import { configModuleMeta } from "@/ui/store/configuration";
import { ThemeSwitcher } from "@/common/service";
import { UserDetails } from "@/common/dto";

export default Vue.extend({
  components: {},
  props: {},
  data() {
    return {
      themes: ThemeSwitcher.getAvailableThemes(),
    };
  },
  methods: {
    magic(themeName: string): void {
      this.$store.dispatch(configModuleMeta.actions.setTheme, themeName);
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isAdmin: function (): boolean {
      return UserDetails.isAdminLocation(this.details);
    },
  },
});
