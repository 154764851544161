import { LocationType } from "@/common/enums";

export class LocationDTO {
  locationCode: string;
  name: string;
  partner: string;
  mainLocation: boolean;
  type: LocationType;

  constructor(
    locationCode: string,
    name: string,
    partner: string,
    mainLocation: boolean,
    type: LocationType
  ) {
    this.locationCode = locationCode;
    this.name = name;
    this.partner = partner;
    this.mainLocation = mainLocation;
    this.type = type;
  }
}
