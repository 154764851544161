import { DiffDataType } from "@/common/enums";

export class DiffDTO {
  oldValue: string | null;
  oldType: DiffDataType;
  newValue: string | null;
  newType: DiffDataType;

  constructor(
    oldValue: string | null,
    oldType: DiffDataType,
    newValue: string | null,
    newType: DiffDataType
  ) {
    this.oldValue = oldValue;
    this.oldType = oldType;
    this.newValue = newValue;
    this.newType = newType;
  }
}
