
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    tags: {
      type: Array as PropType<string[]>,
    },
  },
  computed: {},
});
