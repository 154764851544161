enum DiffType {
  OTHER = "OTHER",
  CASE = "CASE",
  INJURED_PARTY_ADDRESS = "INJURED_PARTY_ADDRESS",
  OPPONENT_OWNER_ADDRESS = "OPPONENT_OWNER_ADDRESS",
  OPPONENT_DRIVER_ADDRESS = "OPPONENT_DRIVER_ADDRESS",
  WITNESS_ADDRESS = "WITNESS_ADDRESS",
  ATTACHMENT = "ATTACHMENT",
  COMMENT = "COMMENT",
  EVENT = "EVENT",
  ATTACHMENT_TAG = "ATTACHMENT_TAG",
  EVALUATION_VALUES = "EVALUATION_VALUES",
}

export default DiffType;
