
import Vue, { PropType } from "vue";
import { CaseDTO, LegalInsuranceDTO } from "@/common/dto";
import i18n from "@/ui/i18n";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: Object as PropType<CaseDTO>,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      items: [] as LegalInsuranceDTO[],
      query: "",
    };
  },
  computed: {
    legalInsuranceLabel(): string {
      if (this.disabled && this.value.legalInsurance) {
        return `${this.label} (${this.$t("UI.form.other")})`;
      } else {
        return `${this.label}`;
      }
    },
    localCase: {
      get: function (): CaseDTO {
        return this.value as unknown as CaseDTO;
      },
      set: function (value: CaseDTO) {
        this.$emit("valueChanged", value);
      },
    },
    filteredDataArray(): LegalInsuranceDTO[] {
      const results = this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.query.toLowerCase());
      });

      if (results.length > 0) {
        return results;
      } else {
        return [{ legalInsuranceId: "", name: this.query }];
      }
    },
    name(): string {
      const result = this.items.find((obj) => {
        return obj.legalInsuranceId === this.localCase.legalInsuranceId;
      });

      return result !== undefined
        ? result.name
        : this.localCase.legalInsurance
        ? this.localCase.legalInsurance
        : i18n.t("UI.enum.general.UNKNOWN");
    },
  },
  methods: {
    handleInput: function (value: string) {
      this.query = value;
      const result = this.items.find((item) => item.name.includes(this.query));
      if (!result) {
        this.localCase.legalInsurance = this.query;
        this.localCase.legalInsuranceId = "";
      } else {
        this.localCase.legalInsuranceId = result.legalInsuranceId;
        this.localCase.legalInsurance = "";
      }
    },
  },
  async created() {
    await new DataHttpClient()
      .loadLegalInsurances()
      .then((res) => {
        this.items = res;
        const selectedInsurance = this.items.find(
          (item) => item.legalInsuranceId === this.localCase.legalInsuranceId
        );
        if (selectedInsurance) {
          this.query = selectedInsurance.name;
        } else if (this.localCase.legalInsurance) {
          this.query = this.localCase.legalInsurance;
        }
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.legalInsurance"),
          err.message
        );
      });
  },
});
