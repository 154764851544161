<template>
  <div>
    <ComposeComment ref="compose" @newComment="createComment" />
    <ul>
      <li v-for="comment in comments" :key="comment.commentId">
        <Comment :comment="comment" @updateComment="updateComment" />
      </li>
    </ul>
  </div>
</template>

<script lang="js">
import ComposeComment from "@/ui/components/detail/comments/ComposeComment.vue";
import Comment from "@/ui/components/detail/comments/Comment.vue";
import { CommentsHttpClient } from "@/common/http";
import moment from "moment/moment";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import Vue from "vue";
import { CommentDTO } from "@/common/dto";
import { Status } from "@/common/enums";

export default Vue.extend({
  name: "CommentsView",
  components: { ComposeComment, Comment },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      loading: true,
    };
  },
  methods: {
    refresh() {
      this.loading = true;
      new CommentsHttpClient()
        .loadComments(this.caseId)
        .then((res) => {
          this.comments = res.map(function (c) {
            c.creationDate = moment(c.creationDate).toDate();
            return c;
          });
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.comments.loading"),
            err.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateComment(payload) {
      new CommentsHttpClient()
        .updateComment(payload)
        .then(() => {
          this.refresh();
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.comments.create"),
            err.message
          );
        });
    },
    createComment(event) {
      const comment = new CommentDTO(
        event.comment,
        undefined,
        event.notifiedUsers
      );
      new CommentsHttpClient()
        .saveComment(this.caseId, comment)
        .then(() => {
          const message =
            this.status === Status.WORK_IN_PROGRESS
              ? i18n.t("UI.message.commentAddedUnreleased")
              : i18n.t("UI.message.commentAddedReleased");
          BuefyHelpers.show_success_notification(
            this.$buefy,
            i18n.t("UI.message.success"),
            message
          );
          this.$refs.compose.reset();
          this.refresh();
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.comments.create"),
            err.message
          );
        });
    },
  },
  mounted() {
    this.refresh();
  },
});
</script>

<style scoped>
ul {
  list-style-type: none !important;
}
</style>
