
import Vue from "vue";
import { userModuleMeta } from "@/ui/store/user";
import { mapGetters } from "vuex";
import i18n from "@/ui/i18n";
import { BuefyHelpers } from "@/common/helpers";
import { NamedRoutes } from "@/ui/router";
import { UserDetails } from "@/common/dto";

export default Vue.extend({
  components: {},
  data() {
    return { toggle: true, NamedRoutes, i18n };
  },
  methods: {},
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isAdmin: function (): boolean {
      return UserDetails.isAdminLocation(this.details);
    },
    isUserListActive: function (): boolean {
      return NamedRoutes.ADMIN_USER_LIST === this.$route.name;
    },
    isJLActive: function (): boolean {
      return NamedRoutes.ADMIN_JL === this.$route.name;
    },
  },
  created() {
    window.scrollTo(0, 0);
    if (!this.isAdmin) {
      BuefyHelpers.show_request_exception_notification(
        this.$buefy,
        i18n.t("UI.error.general.title"),
        i18n.t("UI.error.general.notAllowed")
      );
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    }
  },
});
