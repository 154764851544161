
import Vue, { PropType } from "vue";
import moment from "moment";
import { CaseDTO, UserDetails } from "@/common/dto";
import { Action, DamageType, DecisionEnum, Ownership } from "@/common/enums";

import {
  AddressInput,
  BaseInput,
  BaseTextarea,
  CountrySelect,
  DamageTypeSelect,
  DateInput,
  DecisionSelect,
  InsuranceSelect,
  LocationSelect,
  OwnershipSelect,
  RentalSelect,
  RepairSelect,
  ReporterSelect,
  RoadworthySelect,
  Iban,
} from "@/ui/components/common/forms";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import LegalInsuranceSelect from "@/ui/components/common/forms/LegalInsuranceSelect.vue";
import ExpertForm from "@/ui/components/common/forms/ExpertForm.vue";

export default Vue.extend({
  components: {
    ExpertForm,
    LegalInsuranceSelect,
    BaseTextarea,
    DateInput,
    CountrySelect,
    DamageTypeSelect,
    LocationSelect,
    InsuranceSelect,
    RepairSelect,
    RentalSelect,
    OwnershipSelect,
    DecisionSelect,
    AddressInput,
    BaseInput,
    RoadworthySelect,
    ReporterSelect,
    Iban,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    caseForm: {
      type: Object as PropType<CaseDTO>,
    },
    action: {
      type: Number as PropType<Action>,
      required: true,
      default: Action.CREATE,
    },
  },
  model: {
    prop: "caseForm",
    event: "formchange",
  },
  data() {
    return {
      moment,
      Action,
      DecisionEnum,
      Ownership,
      DamageType,
      UserDetails,
    };
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isAdmin: function (): boolean {
      return UserDetails.isAdminLocation(this.details);
    },
    isCaseHandler: function (): boolean {
      return this.isAdmin || UserDetails.isCaseHandler(this.details);
    },
    witnessAvailable(): boolean {
      return (this.aCase.witnessAvailable as DecisionEnum) === DecisionEnum.YES;
    },
    driverIsNotOwner(): boolean {
      return (this.aCase.driverIsOwner as DecisionEnum) === DecisionEnum.NO;
    },
    version(): number {
      return this.aCase.version + 1;
    },
    isLocationDisabled(): boolean {
      if (!this.disabled) {
        if (this.isCaseHandler || UserDetails.isAdmin(this.details)) {
          return false;
        } else {
          // If there are more than 1, it's enabled
          return (
            this.details.locations.size === 1 &&
            !UserDetails.isMainLocation(this.details)
          );
        }
      }
      return this.disabled;
    },
    aCase: {
      get: function (): CaseDTO {
        return this.caseForm;
      },
      set: function (value: CaseDTO) {
        this.$emit("formchange", value);
      },
    },
  },
});
