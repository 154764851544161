import { BaseClient } from "@/common/http/BaseClient";
import { LocationType } from "@/common/enums";
import { UserDetails } from "@/common/dto";

export class UserHttpClient extends BaseClient {
  private readonly getLocationTypePath = "/get-location-type";
  private readonly geDetailsPath = "/details";

  constructor() {
    super("/user");
  }

  async getLocationTypes(): Promise<LocationType[]> {
    const url = this.url(this.getLocationTypePath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }

  async getDetails(): Promise<UserDetails> {
    const url = this.url(this.geDetailsPath);
    const req_config = this.createRequestConfig(url);

    const res = await this.axios.request(req_config);
    return res.data;
  }
}
