
import Vue, { PropType } from "vue";
import { DiffType } from "@/common/enums";
import { DiffDTO } from "@/common/dto";
import DiffValue from "@/ui/components/detail/history/DiffValue.vue";

export default Vue.extend({
  components: { DiffValue },
  props: {
    diffType: {
      type: String as PropType<DiffType>,
    },
    field: {
      type: String,
    },
    diff: {
      type: Object as PropType<DiffDTO>,
    },
  },
  methods: {},
  computed: {},
});
