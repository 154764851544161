import { AttachmentTag } from "@/common/enums";

export class AttachmentDTO {
  attachmentId: string;
  caseId: string;
  signable: boolean;
  fileName: string;
  created: Date;
  tag: AttachmentTag;

  constructor(
    attachmentId: string,
    caseId: string,
    signable: boolean,
    fileName: string,
    created: Date,
    tag: AttachmentTag
  ) {
    this.attachmentId = attachmentId;
    this.caseId = caseId;
    this.signable = signable;
    this.fileName = fileName;
    this.created = created;
    this.tag = tag;
  }
}
