
import Vue from "vue";
import StatusIcon from "./StatusIcon.vue";
import { NamedRoutes } from "@/ui/router";
import { OverviewItemDTO, UserDetails } from "@/common/dto";
import moment from "moment";
import { RepairDecision, Status } from "@/common/enums";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import { DtoHelpers } from "@/common/helpers";
import { CaseTags } from "@/ui/components/common";

export default Vue.extend({
  components: { StatusIcon, CaseTags },
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isCaseHandler: function (): boolean {
      return (
        UserDetails.isAdminLocation(this.details) ||
        UserDetails.isCaseHandler(this.details)
      );
    },
    showLocation: function (): boolean {
      return (
        UserDetails.isMainLocation(this.details) ||
        this.isCaseHandler ||
        this.details.locations.size > 1
      );
    },
    detail() {
      return NamedRoutes.DETAIL;
    },
    edit() {
      return NamedRoutes.EDIT;
    },
  },
  data() {
    return {
      Status,
      RepairDecision,
    };
  },
  methods: {
    title(item: OverviewItemDTO): string {
      return DtoHelpers.generate_overview_title(item);
    },
    openDetail(id: string) {
      this.$router.push({
        name: NamedRoutes.DETAIL,
        params: { caseId: id },
      });
    },
    checkDate(date: Date) {
      if (date !== undefined) {
        if (moment(date).isValid()) {
          return date;
        }
      }
      return undefined;
    },
    formatDateString(date: Date) {
      const res = this.checkDate(date);
      if (res !== undefined) {
        return moment(date).format("DD.MM.YYYY HH:mm");
      }
      return undefined;
    },
    toEdit(caseId: string) {
      this.$router.push({
        name: NamedRoutes.CREATE,
        params: { caseId: caseId },
      });
    },
  },
});
