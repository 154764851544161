
import Vue, { PropType } from "vue";
import { event_bus } from "@/main";
import { SORT_DIRECTION_CHANGED, SORT_FIELD_CHANGED } from "@/common/events";
import { SortDirection, SortField } from "@/common/enums";

export default Vue.extend({
  props: {
    sortField: {
      type: String as PropType<SortField>,
      required: true,
    },
    sortDirection: {
      type: String as PropType<SortDirection>,
      required: true,
    },
  },
  data() {
    return {
      SortField,
    };
  },
  methods: {
    switch_direction: function () {
      this.localSortDirection =
        this.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC;
    },
  },
  computed: {
    sorting_icon(): string {
      return this.sortDirection === SortDirection.ASC
        ? "sort-amount-down-alt"
        : "sort-amount-up-alt";
    },
    localSortField: {
      get: function (): SortField {
        return this.sortField;
      },
      set: function (value: SortField) {
        event_bus.$emit(SORT_FIELD_CHANGED, value);
      },
    },
    localSortDirection: {
      get: function (): string {
        return this.sortDirection;
      },
      set: function (value: string) {
        event_bus.$emit(SORT_DIRECTION_CHANGED, value);
      },
    },
  },
});
