import { ColorCallSet } from "bulma-css-vars/dist/types";

export type ColorFn =
  | "rgba"
  | "adjusthue"
  | "saturate"
  | "desaturate"
  | "lighten"
  | "darken"
  | "color-invert"
  | "dark-color"
  | "light-color";

export interface ColorFnCall {
  fn: ColorFn;
  fnArg: string | null;
  composeArg: ColorFnCall | null;
}

export const bulmaCssVariablesDefs: ColorCallSet = {
  dark: {
    calls: [
      {
        fn: "darken",
        fnArg: "1000",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "5",
        composeArg: undefined,
      },
      {
        fn: "darken",
        fnArg: "500",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "80",
        composeArg: undefined,
      },
      {
        fn: "lighten",
        fnArg: "800",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "90",
        composeArg: undefined,
      },
      {
        fn: "color-invert",
        fnArg: "",
        composeArg: undefined,
      },
    ],
  },
  light: {
    calls: [
      {
        fn: "darken",
        fnArg: "1000",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "5",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "80",
        composeArg: undefined,
      },
      {
        fn: "lighten",
        fnArg: "800",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "90",
        composeArg: undefined,
      },
      {
        fn: "color-invert",
        fnArg: "",
        composeArg: undefined,
      },
    ],
  },
  primary: {
    calls: [
      {
        fn: "darken",
        fnArg: "1000",
        composeArg: undefined,
      },
      {
        fn: "lighten",
        fnArg: "150",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "5",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "50",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "80",
        composeArg: undefined,
      },
      {
        fn: "rgba",
        fnArg: "90",
        composeArg: undefined,
      },
      {
        fn: "color-invert",
        fnArg: "",
        composeArg: undefined,
      },
      {
        fn: "dark-color",
        fnArg: "",
        composeArg: undefined,
      },
      {
        fn: "light-color",
        fnArg: "",
        composeArg: undefined,
      },
    ],
  },
};
