
import Vue, { PropType } from "vue";
import moment from "moment";
import BaseLabel from "@/ui/components/common/forms/BaseLabel.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: { BaseLabel },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    value: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      local_model: new Date(),
    };
  },
  methods: {
    clear: function (): void {
      this.local_value = null;
    },
    today: function (): void {
      this.local_value = new Date();
    },
  },
  computed: {
    local_value: {
      get: function (): Date | string | null {
        return this.value;
      },
      set: function (value: Date | null) {
        this.$emit("valueChanged", value);
      },
    },
    value_string: function (): string {
      if (
        this.local_value != null &&
        this.local_value.toString() !== "Invalid Date"
      ) {
        return moment(this.local_value).format("DD.MM.YYYY");
      }
      return "";
    },
  },
  watch: {
    local_model: function () {
      if (
        this.local_model != null &&
        this.local_model.toString() !== "Invalid Date"
      ) {
        this.local_model.setHours(12);
        this.local_value = this.local_model;
      }
    },
  },
  created() {
    if (
      this.local_value != null &&
      this.local_value instanceof Date &&
      this.local_value.toString() !== "Invalid Date"
    ) {
      this.local_model = this.local_value;
    }
  },
});
