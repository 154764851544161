
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  components: {},
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    label: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    keys: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    i18nKey: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    get_key: function (index: number) {
      if (this.keys.length === 0) {
        return this.items[index];
      }

      return this.keys[index];
    },
  },
  computed: {
    localValue: {
      get: function (): Array<string> {
        return this.value;
      },
      set: function (value: Array<string>) {
        this.$emit("valueChanged", value);
      },
    },
  },
});
