var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('advisory-select',{attrs:{"disabled":_vm.disabled,"label":_vm.$t('UI.form.advisory')},model:{value:(_vm.localValue.advisory),callback:function ($$v) {_vm.$set(_vm.localValue, "advisory", $$v)},expression:"localValue.advisory"}}),(_vm.expert_fields_needed)?_c('div',[(_vm.appraisers.length > 0)?_c('expert-select',{attrs:{"disabled":_vm.disabled || _vm.UserDetails.isAppraiser(_vm.details),"label":_vm.$t('UI.form.expert_office'),"appraisers":_vm.appraisers},model:{value:(_vm.localValue.expertOfficeCode),callback:function ($$v) {_vm.$set(_vm.localValue, "expertOfficeCode", $$v)},expression:"localValue.expertOfficeCode"}}):_vm._e(),(
        _vm.appraisers.length === 0 || _vm.localValue.expertOfficeCode === 'OTHER'
      )?_c('base-input',{attrs:{"disabled":_vm.disabled,"label":_vm.addOtherSuffix(
          _vm.$t('UI.form.expert_office'),
          _vm.localValue.expertOfficeCode
        )},model:{value:(_vm.localValue.expertOffice),callback:function ($$v) {_vm.$set(_vm.localValue, "expertOffice", $$v)},expression:"localValue.expertOffice"}}):_vm._e(),(
        _vm.localValue.expertOfficeCode !== '' &&
        _vm.localValue.expertOfficeCode !== 'OTHER'
      )?_c('expert-employee-select',{attrs:{"disabled":_vm.disabled,"appraiser":_vm.localValue.expertOfficeCode,"label":_vm.$t('UI.form.expert_employee')},model:{value:(_vm.localValue.expertEmployeeCode),callback:function ($$v) {_vm.$set(_vm.localValue, "expertEmployeeCode", $$v)},expression:"localValue.expertEmployeeCode"}}):_vm._e(),(
        _vm.localValue.expertOfficeCode === '' ||
        _vm.localValue.expertOfficeCode === 'OTHER' ||
        _vm.localValue.expertEmployeeCode === 'OTHER'
      )?_c('base-input',{attrs:{"disabled":_vm.disabled,"label":_vm.addOtherSuffix(
          _vm.$t('UI.form.expert_employee'),
          _vm.localValue.expertEmployeeCode
        )},model:{value:(_vm.localValue.expertEmployee),callback:function ($$v) {_vm.$set(_vm.localValue, "expertEmployee", $$v)},expression:"localValue.expertEmployee"}}):_vm._e(),_c('base-input',{attrs:{"disabled":_vm.disabled,"label":_vm.$t('UI.form.expert_reference')},model:{value:(_vm.localValue.expertReference),callback:function ($$v) {_vm.$set(_vm.localValue, "expertReference", $$v)},expression:"localValue.expertReference"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }