import { BaseClient } from "@/common/http/BaseClient";
import { HistoryDTO } from "@/common/dto";

export class HistoryHttpClient extends BaseClient {
  private readonly loadOverviewPath = "";

  constructor() {
    super("/history");
  }

  async loadHistory(caseId: string): Promise<HistoryDTO[]> {
    const url = this.urlWithId(this.loadOverviewPath, caseId);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
