import { LocalStorageConstants } from "@/common/enums";

export class LocalStorageHelpers {
  static store_values(values: StoragePair[]): void {
    values.forEach((v) => {
      localStorage.setItem(v.key, v.value);
    });
  }

  static get_values(keys: LocalStorageConstants[]): StoragePair[] {
    return keys
      .map((k) => {
        const item = localStorage.getItem(k);
        if (item !== null) {
          return LocalStorageHelpers.pair(k, item);
        }
      })
      .filter(function <TValue>(
        value: TValue | null | undefined
      ): value is TValue {
        return !(value === null || value === undefined);
      });
  }

  static clear_values(keys: LocalStorageConstants[]): void {
    keys.forEach((k) => localStorage.removeItem(k));
  }

  static pair(key: LocalStorageConstants, value: string): StoragePair {
    return { key: key, value: value };
  }

  static case_filter_keys(): LocalStorageConstants[] {
    return [
      LocalStorageConstants.LOCATION_FILTER,
      LocalStorageConstants.CASE_HANDLER_FILTER,
      LocalStorageConstants.REPAIR_DECISION_FILTER,
      LocalStorageConstants.STATUS_FILTER,
      LocalStorageConstants.LICENSE_PLATE_FILTER,
      LocalStorageConstants.FILE_SIGN_FILTER,
      LocalStorageConstants.CUSTOMER_NAME_FILTER,
      LocalStorageConstants.CREATED_FILTER,
      LocalStorageConstants.UPDATED_FILTER,
      LocalStorageConstants.CLOSED_FILTER,
      LocalStorageConstants.PRECONFIGURED_FILTER,
      LocalStorageConstants.DAMAGE_TYPE_FILTER,
    ];
  }
}

export interface StoragePair {
  key: LocalStorageConstants;
  value: string;
}

export const StoragePair: StoragePair = {
  key: LocalStorageConstants.NONE,
  value: "",
};
