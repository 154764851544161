
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
    tooltip: {
      type: String as PropType<TranslateResult>,
      required: false,
      default: "",
    },
  },
  methods: {},
  computed: {},
});
