enum DiffDataType {
  STRING = "STRING",
  ENUM = "ENUM",
  UUID = "UUID",
  DATETIME = "DATETIME",
  NULL = "NULL",
  OTHER = "OTHER",
}

export default DiffDataType;
