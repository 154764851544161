
import Vue, { PropType } from "vue";
import moment from "moment";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "Evaluation",
  props: {
    label: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    date: {
      type: [Date, Object],
      required: true,
    },
  },
  data() {
    return {
      moment,
      Date,
    };
  },
});
