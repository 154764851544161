
import Vue from "vue";
import { BodyTable } from "@/ui/components/overview";
import { OverviewDTO } from "@/common/dto";
import i18n from "@/ui/i18n";
import CaseFilter from "@/ui/components/overview/CaseFilter.vue";
import { OverviewHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import Pagination from "@/ui/components/overview/Pagination.vue";
import { event_bus } from "@/main";
import {
  CASE_FILTER_INITIALIZED,
  PER_PAGE_CHANGED,
  SORT_DIRECTION_CHANGED,
  SORT_FIELD_CHANGED,
} from "@/common/events";
import { SortDirection, SortField } from "@/common/enums";
import SortingSelector from "@/ui/components/overview/SortingSelector.vue";

export default Vue.extend({
  components: { SortingSelector, Pagination, CaseFilter, BodyTable },
  methods: {
    async reload() {
      if (this.initialized) {
        localStorage.setItem("per_page", JSON.stringify(this.per_page));
        localStorage.setItem("sort_field", JSON.stringify(this.sort_field));
        localStorage.setItem(
          "sort_direction",
          JSON.stringify(this.sort_direction)
        );

        this.loading = true;
        await new OverviewHttpClient()
          .loadOverview(
            this.filters,
            this.page - 1,
            this.per_page,
            this.sort_field,
            this.sort_direction
          )
          .then((res) => {
            this.overview = res;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.overview.loadingCases"),
              err.message
            );
          });
      }
    },
    closeMessage() {
      localStorage.setItem("message_read", JSON.stringify(true));
    },
  },
  data() {
    return {
      overview: OverviewDTO.empty(),
      filters: [],
      errors: [],
      page: 1,
      per_page: 20,
      loading: false,
      initialized: false,
      sort_direction: SortDirection.DESC,
      sort_field: SortField.LAST_MODIFICATION_DATE,
      SortField,
    };
  },
  watch: {
    page: function () {
      this.reload();
    },
    per_page: function () {
      this.reload();
    },
    sort_field: function () {
      this.reload();
    },
    sort_direction: function () {
      this.reload();
    },
  },
  created() {
    window.scrollTo(0, 0);
    const per_page = localStorage.getItem("per_page");
    const sort_field = localStorage.getItem("sort_field");
    const sort_direction = localStorage.getItem("sort_direction");

    if (per_page != null) {
      this.per_page = JSON.parse(per_page);
    }
    if (sort_field != null) {
      this.sort_field = JSON.parse(sort_field);
    }
    if (sort_direction != null) {
      this.sort_direction = JSON.parse(sort_direction);
    }
    event_bus.$on(PER_PAGE_CHANGED, (data: number) => {
      this.per_page = data;
    });
    event_bus.$on(SORT_FIELD_CHANGED, (data: SortField) => {
      this.sort_field = data;
    });
    event_bus.$on(SORT_DIRECTION_CHANGED, (data: SortDirection) => {
      this.sort_direction = data;
    });
    event_bus.$on(CASE_FILTER_INITIALIZED, (data: boolean) => {
      this.initialized = data;
    });
  },
  computed: {
    shownCases: function (): string {
      const first_item = this.page * this.per_page - this.per_page + 1;
      const begin = first_item >= 0 ? first_item : 1;
      const last_item = this.page * this.per_page;
      const end =
        last_item > this.overview.allCases ? this.overview.allCases : last_item;
      const filtered = begin + " - " + end;

      return i18n.t("UI.overview.filteredCases", {
        filtered: filtered,
        all: this.overview.allCases,
      });
    },
    isMessageRead: function (): boolean {
      const message_read = localStorage.getItem("message_read");
      return message_read != null;
    },
  },
});
