
import Vue, { PropType } from "vue";
import { FilterDTO, LocationDTO, UserDetails } from "@/common/dto";
import {
  CaseFilters,
  FilterField,
  LocalStorageConstants,
  RepairDecision,
  Status,
  DamageType,
} from "@/common/enums";
import { ListFilter } from "@/ui/components/overview/filter";
import { AdminHttpClient, DataHttpClient } from "@/common/http";
import { BuefyHelpers, LocalStorageHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { userModuleMeta } from "@/ui/store/user";
import { mapGetters } from "vuex";
import DateFilter from "@/ui/components/overview/filter/DateFilter.vue";
import TextFilter from "@/ui/components/overview/filter/TextFilter.vue";
import { event_bus } from "@/main";
import {
  CASE_FILTER_INITIALIZED,
  REPAIR_DATE_MISSING_ADDED,
} from "@/common/events";
import PreConfigured from "@/ui/components/overview/filter/PreConfigured.vue";

export default Vue.extend({
  components: { PreConfigured, TextFilter, DateFilter, ListFilter },
  model: {
    prop: "filters",
    event: "valueChanged",
  },
  props: {
    filters: {
      type: Array as PropType<FilterDTO[]>,
      default: [] as FilterDTO[],
    },
  },
  data() {
    return {
      locationsLoaded: false,
      initialized: false,
      type: FilterField.NONE,
      status: Status.WORK_IN_PROGRESS,
      location: "",
      repair_date_missing: false,
      repair: RepairDecision.NONE,
      location_filter: [] as string[],
      caseHandlerFilter: [] as string[],
      repair_decision_filter: [] as string[],
      damage_type_filter: [] as string[],
      status_filter: [] as string[],
      preconfigured_filter: [] as string[],
      license_plate_filter: "",
      file_sign_filter: "",
      customer_name_filter: "",
      expert_reference_filter: "",
      created_filter: [] as Date[],
      updated_filter: [] as Date[],
      closed_filter: [] as Date[],
      locations: [] as LocationDTO[],
      caseHandlers: [] as LocationDTO[],
      caseHandlersLoaded: false,
      CaseFilters,
      FilterField,
      RepairDecision,
      Status,
      DamageType,
      REPAIR_DATE_MISSING_ADDED,
    };
  },
  watch: {
    $data: {
      handler: function () {
        const new_filters = [] as FilterDTO[];

        if (this.location_filter.length > 0) {
          new_filters.push(
            ...this.location_filter.map(
              (l) => new FilterDTO(FilterField.LOCATION, l, undefined)
            )
          );
        }
        if (this.caseHandlerFilter.length > 0) {
          new_filters.push(
            ...this.caseHandlerFilter.map(
              (caseHandler) =>
                new FilterDTO(FilterField.CASE_HANDLER, caseHandler, undefined)
            )
          );
        }

        if (this.repair_decision_filter.length > 0) {
          new_filters.push(
            ...this.repair_decision_filter.map(
              (r) => new FilterDTO(FilterField.REPAIR_WANTED, r, undefined)
            )
          );
        }
        if (this.damage_type_filter.length > 0) {
          new_filters.push(
            ...this.damage_type_filter.map(
              (r) => new FilterDTO(FilterField.DAMAGE_TYPE, r, undefined)
            )
          );
        }
        if (this.status_filter.length > 0) {
          new_filters.push(
            ...this.status_filter.map(
              (s) => new FilterDTO(FilterField.STATUS, s, undefined)
            )
          );
        }

        if (this.license_plate_filter !== "") {
          new_filters.push(
            new FilterDTO(
              FilterField.LICENSE_PLATE,
              this.license_plate_filter,
              undefined
            )
          );
        }

        if (this.file_sign_filter !== "") {
          new_filters.push(
            new FilterDTO(
              FilterField.FILE_SIGN,
              this.file_sign_filter,
              undefined
            )
          );
        }

        if (this.customer_name_filter !== "") {
          new_filters.push(
            new FilterDTO(
              FilterField.CUSTOMER_NAME,
              this.customer_name_filter,
              undefined
            )
          );
        }

        if (this.expert_reference_filter !== "") {
          new_filters.push(
            new FilterDTO(
              FilterField.EXPERT_REFERENCE,
              this.expert_reference_filter,
              undefined
            )
          );
        }

        if (this.created_filter.length > 0) {
          new_filters.push(
            new FilterDTO(FilterField.CREATED, undefined, this.created_filter)
          );
        }
        if (this.updated_filter.length > 0) {
          new_filters.push(
            new FilterDTO(FilterField.UPDATED, undefined, this.updated_filter)
          );
        }
        if (this.closed_filter.length > 0) {
          new_filters.push(
            new FilterDTO(FilterField.CLOSED, undefined, this.closed_filter)
          );
        }

        this.preconfigured_filter.forEach((filter) =>
          new_filters.push(
            new FilterDTO(FilterField.PRECONFIGURED, filter, undefined)
          )
        );

        if (this.initialized) {
          this.localFilters = new_filters;
          LocalStorageHelpers.store_values([
            LocalStorageHelpers.pair(
              LocalStorageConstants.LOCATION_FILTER,
              JSON.stringify(this.location_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.CASE_HANDLER_FILTER,
              JSON.stringify(this.caseHandlerFilter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.REPAIR_DECISION_FILTER,
              JSON.stringify(this.repair_decision_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.DAMAGE_TYPE_FILTER,
              JSON.stringify(this.damage_type_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.STATUS_FILTER,
              JSON.stringify(this.status_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.LICENSE_PLATE_FILTER,
              JSON.stringify(this.license_plate_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.FILE_SIGN_FILTER,
              JSON.stringify(this.file_sign_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.CUSTOMER_NAME_FILTER,
              JSON.stringify(this.customer_name_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.EXPERT_REFERENCE_FILTER,
              JSON.stringify(this.expert_reference_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.CREATED_FILTER,
              JSON.stringify(this.created_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.UPDATED_FILTER,
              JSON.stringify(this.updated_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.CLOSED_FILTER,
              JSON.stringify(this.closed_filter)
            ),
            LocalStorageHelpers.pair(
              LocalStorageConstants.PRECONFIGURED_FILTER,
              JSON.stringify(this.preconfigured_filter)
            ),
          ]);
        } else {
          this.initialized = true;
          event_bus.$emit(CASE_FILTER_INITIALIZED, true);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    isCaseHandler: function (): boolean {
      return (
        UserDetails.isAdminLocation(this.details) ||
        UserDetails.isCaseHandler(this.details)
      );
    },
    isAdmin: function (): boolean {
      return UserDetails.isAdminLocation(this.details);
    },
    localFilters: {
      get: function (): FilterDTO[] {
        return this.filters;
      },
      set: function (value: FilterDTO[]): void {
        this.$emit("valueChanged", value);
      },
    },
    location_keys(): Array<string> {
      return this.locations.map((l) => l.locationCode);
    },
    location_names(): Array<string> {
      return this.locations.map((l) => {
        if (this.isCaseHandler) {
          return l.partner + ": " + l.name;
        } else {
          return l.name;
        }
      });
    },
    caseHandlerNames(): Array<string> {
      return this.caseHandlers.map((l) => {
        return l.name;
      });
    },
    caseHandlerKeys(): Array<string> {
      return this.caseHandlers.map((l) => l.locationCode);
    },
    any_filter_added: function (): boolean {
      return (
        this.location_filter.length > 0 ||
        this.caseHandlerFilter.length > 0 ||
        this.repair_decision_filter.length > 0 ||
        this.damage_type_filter.length > 0 ||
        this.status_filter.length > 0 ||
        this.created_filter.length > 0 ||
        this.updated_filter.length > 0 ||
        this.closed_filter.length > 0 ||
        this.license_plate_filter !== "" ||
        this.file_sign_filter !== "" ||
        this.customer_name_filter !== "" ||
        this.expert_reference_filter !== "" ||
        this.repair_date_missing
      );
    },
    loaded: function (): boolean {
      return this.locationsLoaded && (!this.isAdmin || this.caseHandlersLoaded);
    },
  },
  methods: {
    add_repair_date_missing_filter() {
      this.status_filter.push(Status.RELEASED);
      this.repair_decision_filter.push(RepairDecision.CONCRETE);
      this.preconfigured_filter.push(CaseFilters.REPAIR_DATE_MISSING);

      this.status_filter = [...new Set(this.status_filter)];
      this.repair_decision_filter = [...new Set(this.repair_decision_filter)];
      this.preconfigured_filter = [...new Set(this.preconfigured_filter)];
    },
    remove_all() {
      LocalStorageHelpers.clear_values(LocalStorageHelpers.case_filter_keys());
      this.location_filter = [];
      this.caseHandlerFilter = [];
      this.repair_decision_filter = [];
      this.status_filter = [];
      this.created_filter = [];
      this.updated_filter = [];
      this.closed_filter = [];
      this.preconfigured_filter = [];
      this.license_plate_filter = "";
      this.file_sign_filter = "";
      this.customer_name_filter = "";
      this.expert_reference_filter = "";
      this.repair_date_missing = false;
      this.damage_type_filter = [];
    },
    filter_none(array: Array<string>): Array<string> {
      return array.filter((i) => i != "NONE" && i != "HIDDEN");
    },
    initialize_filters() {
      LocalStorageHelpers.get_values(
        LocalStorageHelpers.case_filter_keys()
      ).forEach((pair) => {
        try {
          switch (pair.key) {
            case LocalStorageConstants.LOCATION_FILTER:
              this.location_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.CASE_HANDLER_FILTER:
              this.caseHandlerFilter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.REPAIR_DECISION_FILTER:
              this.repair_decision_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.DAMAGE_TYPE_FILTER:
              this.damage_type_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.STATUS_FILTER:
              this.status_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.CREATED_FILTER: {
              const createdDateStrings: string[] = JSON.parse(pair.value);
              this.created_filter = createdDateStrings.map(
                (dateString) => new Date(dateString)
              );
              break;
            }
            case LocalStorageConstants.UPDATED_FILTER: {
              const updatedDateStrings: string[] = JSON.parse(pair.value);
              this.updated_filter = updatedDateStrings.map(
                (dateString) => new Date(dateString)
              );
              break;
            }
            case LocalStorageConstants.CLOSED_FILTER: {
              const closedDateStrings: string[] = JSON.parse(pair.value);
              this.closed_filter = closedDateStrings.map(
                (dateString) => new Date(dateString)
              );
              break;
            }
            case LocalStorageConstants.LICENSE_PLATE_FILTER:
              this.license_plate_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.FILE_SIGN_FILTER:
              this.file_sign_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.CUSTOMER_NAME_FILTER:
              this.customer_name_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.EXPERT_REFERENCE_FILTER:
              this.expert_reference_filter = JSON.parse(pair.value);
              break;
            case LocalStorageConstants.PRECONFIGURED_FILTER:
              this.preconfigured_filter = JSON.parse(pair.value);
              if (
                this.preconfigured_filter.includes(
                  CaseFilters.REPAIR_DATE_MISSING
                )
              ) {
                this.repair_date_missing = true;
              }
              break;
          }
        } catch (ex) {
          console.warn("Cannot parse value for filter:", pair);
        }
      });

      this.location_filter = [...new Set(this.location_filter)];
      this.caseHandlerFilter = [...new Set(this.caseHandlerFilter)];
      this.repair_decision_filter = [...new Set(this.repair_decision_filter)];
      this.damage_type_filter = [...new Set(this.damage_type_filter)];
      this.status_filter = [...new Set(this.status_filter)];
      this.created_filter = [...new Set(this.created_filter)];
      this.updated_filter = [...new Set(this.updated_filter)];
      this.closed_filter = [...new Set(this.closed_filter)];
      this.preconfigured_filter = [...new Set(this.preconfigured_filter)];
    },
  },
  async mounted() {
    event_bus.$on(REPAIR_DATE_MISSING_ADDED, () => {
      this.add_repair_date_missing_filter();
    });

    this.initialize_filters();

    await new DataHttpClient()
      .loadPhysicalLocations()
      .then((res) => {
        this.locations = res;
        this.locationsLoaded = true;
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.data.location"),
          err.message
        );
      });
    if (this.isAdmin || this.isCaseHandler) {
      await new AdminHttpClient()
        .loadAllCaseHandlers()
        .then((res) => {
          this.caseHandlers = res;
          this.caseHandlersLoaded = true;
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.data.caseHandler"),
            err.message
          );
        });
    }
  },
});
