import { Image } from "@/common/dto";

export class Theme {
  image: Image;
  primary: string;
  dark: string;
  light: string;
  view: string;

  constructor(
    image: Image,
    primary: string,
    dark: string,
    light: string,
    view: string
  ) {
    this.image = image;
    this.primary = primary;
    this.dark = dark;
    this.light = light;
    this.view = view;
  }
}
