
import Vue from "vue";
import FileList from "@/ui/components/detail/attachments/FileList.vue";
import moment from "moment/moment";
import { AttachmentHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import { event_bus } from "@/main";
import { ATTACHMENT_ADDED } from "@/common/events";

export default Vue.extend({
  name: "FileUpload",
  components: { FileList },
  props: {
    caseId: {
      type: String,
      required: false,
      default: "",
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    customerEmailValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dropFiles: [] as File[],
      max_file_size: 20 * 1000000,
    };
  },
  computed: {
    upload_disabled: function (): boolean {
      return (
        this.dropFiles.filter((file) => file.size > this.max_file_size).length >
        0
      );
    },
  },
  methods: {
    deleteDropFile(index: number): void {
      this.dropFiles.splice(index, 1);
    },
    deleteAll() {
      this.dropFiles = [];
    },
    formatDate(date_time: Date): string {
      return moment(date_time).format("DD MM YYYY HH:mm");
    },
    async uploadFiles(): Promise<void> {
      await this.dropFiles.reduce(async (previous, file) => {
        // Wait for the previous item to finish processing
        await previous;
        // Process this item
        await new AttachmentHttpClient()
          .storeAttachment(this.caseId, file)
          .then((res) => {
            BuefyHelpers.show_success_notification(
              this.$buefy,
              i18n.t("UI.message.success"),
              i18n.t("UI.message.attachmentAdded", { fileName: res.fileName })
            );
            event_bus.$emit(ATTACHMENT_ADDED);
            const index = this.dropFiles.indexOf(file);
            this.deleteDropFile(index);
          })
          .catch((err) => {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.attachment.upload"),
              err.message
            );
          });
      }, Promise.resolve());

      return Promise.resolve();
    },
  },
});
