import { Country, Title } from "@/common/enums";

export class AddressDTO {
  addressId: string;

  title: Title;

  company: string;

  firstName: string;
  lastName: string;

  street1: string;
  street2: string;
  buildingNo: string;

  zip: string;
  city: string;
  country: Country;

  email: string;
  phone: string;
  fax: string;

  constructor(
    addressId: string,
    title: Title,
    company: string,
    firstName: string,
    lastName: string,
    street1: string,
    street2: string,
    buildingNo: string,
    zip: string,
    city: string,
    country: Country,
    email: string,
    phone: string,
    fax: string
  ) {
    this.addressId = addressId;
    this.title = title;
    this.company = company;
    this.firstName = firstName;
    this.lastName = lastName;
    this.street1 = street1;
    this.street2 = street2;
    this.buildingNo = buildingNo;
    this.zip = zip;
    this.city = city;
    this.country = country;
    this.email = email;
    this.phone = phone;
    this.fax = fax;
  }
}
