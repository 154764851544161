import { BaseClient } from "@/common/http/BaseClient";
import { FilterDTO, OverviewDTO } from "@/common/dto";
import { SortDirection, SortField } from "@/common/enums";

export class OverviewHttpClient extends BaseClient {
  private readonly loadOverviewPath = "";

  constructor() {
    super("/overview");
  }

  async loadOverview(
    filters: FilterDTO[],
    page: number,
    perPage: number,
    sortField: SortField,
    sortDirection: SortDirection
  ): Promise<OverviewDTO> {
    const url = this.urlWithId(
      this.loadOverviewPath,
      `${page}/${perPage}/${sortField}/${sortDirection}`
    );
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "POST";
    reqConfig.data = filters;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
