import { BaseClient } from "@/common/http/BaseClient";
import { CommentDTO } from "@/common/dto";

export class CommentsHttpClient extends BaseClient {
  private readonly loadPath = "/get";
  private readonly savePath = "/put/save";
  private readonly updatePath = "/put/update";

  constructor() {
    super("/v1/comments");
  }

  async loadComments(id: string): Promise<CommentDTO[]> {
    const url = this.urlWithId(this.loadPath, id);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async saveComment(id: string, comment: CommentDTO): Promise<CommentDTO> {
    const url = this.urlWithId(this.savePath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = comment;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async updateComment(comment: CommentDTO): Promise<CommentDTO> {
    const url = this.url(this.updatePath);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = comment;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
