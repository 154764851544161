
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n/types";

export default Vue.extend({
  components: {},
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },
  data() {
    return {
      debounce: undefined as number | undefined,
    };
  },
  computed: {
    localValue: {
      get: function (): string {
        return this.value;
      },
      set: function (value: string) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.$emit("valueChanged", value);
        }, 800);
      },
    },
  },
  methods: {
    set_focus(triggered: boolean) {
      if (triggered) {
        // eslint-disable-next-line
        const ref = this.$refs.local_value as any;
        ref.focus();
      }
    },
  },
});
