
import Vue, { PropType } from "vue";
import { HistoryItemDTO } from "@/common/dto";
import DiffTable from "@/ui/components/detail/history/DiffTable.vue";
import { DiffType } from "@/common/enums";

export default Vue.extend({
  components: { DiffTable },
  props: {
    item: {
      type: Object as PropType<HistoryItemDTO>,
    },
  },
  data() {
    return {
      DiffType,
    };
  },
  methods: {
    // eslint-disable-next-line
    changes_length(obj: any): number {
      return Object.keys(obj).length;
    },
  },
});
