
import Vue from "vue";
import i18n from "@/ui/i18n";

export default Vue.extend({
  props: {
    status: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    translationKey(): string {
      if (!i18n.te("UI.enum.status." + this.status)) {
        return "UI.message.notAvailable";
      }
      return "UI.enum.status." + this.status;
    },
  },
});
