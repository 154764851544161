enum AsyncResponseStatus {
  SUBMITTED = "SUBMITTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  DELETED = "DELETED",
  UNKNOWN = "UNKNOWN",
}

export default AsyncResponseStatus;
