import { BuefyNamespace } from "buefy";
import { ColorModifiers } from "buefy/types/helpers";

export class BuefyHelpers {
  static show_request_exception_notification(
    buefy: BuefyNamespace,
    headline: string,
    err_message: string
  ): void {
    BuefyHelpers.show_notification(
      buefy,
      "is-danger",
      "exclamation-triangle",
      true,
      headline,
      err_message
    );
  }

  static show_success_notification(
    buefy: BuefyNamespace,
    headline: string,
    message: string
  ): void {
    BuefyHelpers.show_notification(
      buefy,
      "is-success",
      "check",
      false,
      headline,
      message
    );
  }

  private static show_notification(
    buefy: BuefyNamespace,
    type: ColorModifiers,
    icon: string,
    indefinite: boolean,
    headline: string,
    message: string
  ): void {
    buefy.notification.open({
      message: `<strong>${headline}</strong><br>${message}`,
      duration: 10000,
      position: "is-bottom-right",
      type: type,
      hasIcon: true,
      icon: icon,
      queue: false,
      indefinite: indefinite,
    });
  }
}
