import { SimpleUserDTO } from "@/common/dto/SimpleUserDTO";

export class CommentDTO {
  commentId: string;
  createdBy: string;
  fullName: string;
  creationDate: Date | undefined;
  comment: string;
  notifyUser: SimpleUserDTO[];

  constructor(
    comment: string,
    commentId?: string,
    notifyUser?: SimpleUserDTO[],
    createdBy?: string,
    fullName?: string,
    creationDate?: Date | undefined
  ) {
    this.comment = comment;
    this.commentId = commentId || "";
    this.notifyUser = notifyUser || [];
    this.createdBy = createdBy || "";
    this.fullName = fullName || "";
    this.creationDate = creationDate;
  }
}
