import { OverviewItemDTO } from "@/common/dto/OverviewItemDTO";

export class OverviewDTO {
  allCases: number;
  page: number;
  totalPages: number;
  items: OverviewItemDTO[];

  constructor(
    allCases: number,
    page: number,
    totalPages: number,
    items: OverviewItemDTO[]
  ) {
    this.allCases = allCases;
    this.page = page;
    this.totalPages = totalPages;
    this.items = items;
  }

  static empty(): OverviewDTO {
    return new OverviewDTO(0, 0, 0, []);
  }
}
