import { BaseClient } from "@/common/http/BaseClient";
import { AttachmentDTO, PoaData } from "@/common/dto";
import { AttachmentTag } from "@/common/enums";
import { SignatureDTO } from "@/common/dto/SignatureDTO";

export class AttachmentHttpClient extends BaseClient {
  private readonly loadAttachmentsPath = "/get/list";
  private readonly downloadAttachmentPath = "/get/file";
  private readonly signAttachmentPath = "/sign";
  private readonly storeAttachmentPath = "/put/new";
  private readonly createPoaPath = "/put/poa";
  private readonly updateTagPath = "/put/tag";
  private readonly deleteAttachmentPath = "/delete";
  private readonly sendDocumentToCustomerPath = "/send-document";

  constructor() {
    super("/v1/attachments");
  }

  async loadAttachments(id: string): Promise<AttachmentDTO[]> {
    const url = this.urlWithId(this.loadAttachmentsPath, id);
    const reqConfig = this.createRequestConfig(url);

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async downloadAttachment(id: string): Promise<Blob> {
    const url = this.urlWithId(this.downloadAttachmentPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.responseType = "blob";

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async storeAttachment(id: string, file: File): Promise<AttachmentDTO> {
    const form_data = new FormData();
    form_data.append("file", file);

    const url = this.urlWithId(this.storeAttachmentPath, id);
    const reqConfig = this.createMultipartRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = form_data;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async signAttachment(
    id: string,
    signature: SignatureDTO
  ): Promise<AttachmentDTO> {
    const url = this.urlWithId(this.signAttachmentPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = signature;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async updateTag(id: string, tag: AttachmentTag): Promise<boolean> {
    const url = this.urlWithId(this.updateTagPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = tag;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async createPoa(id: string, poaData: PoaData): Promise<AttachmentDTO> {
    const url = this.urlWithId(this.createPoaPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "PUT";
    reqConfig.data = poaData;

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async deleteAttachment(id: string): Promise<boolean> {
    const url = this.urlWithId(this.deleteAttachmentPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "DELETE";

    const { data } = await this.axios.request(reqConfig);
    return data;
  }

  async sendDocumentToCustomer(id: string): Promise<boolean> {
    const url = this.urlWithId(this.sendDocumentToCustomerPath, id);
    const reqConfig = this.createRequestConfig(url);

    reqConfig.method = "GET";

    const { data } = await this.axios.request(reqConfig);
    return data;
  }
}
