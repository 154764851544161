
import Vue, { PropType } from "vue";
import moment from "moment";
import { TranslateResult } from "vue-i18n/types";

export default Vue.extend({
  components: {},
  model: {
    prop: "value",
    event: "value_changed",
  },
  props: {
    value: {
      type: Array as PropType<Array<Date>>,
      required: true,
    },
    label: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "calendar",
    },
  },
  computed: {
    local_value: {
      get: function (): Array<Date> {
        return this.value;
      },
      set: function (value: Array<Date>) {
        this.$emit(
          "value_changed",
          value.map((d) => moment(d).set("h", 12).toDate())
        );
      },
    },
  },
});
