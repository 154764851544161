import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { UserModule } from "@/ui/store/user";
import { ConfigModule } from "@/ui/store/configuration";

Vue.use(Vuex);

export interface RootState {
  version: string;
}

const options: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
  },
  modules: {
    userModule: UserModule,
    configModule: ConfigModule,
  },
};

const store = new Vuex.Store(options);

export default store;
