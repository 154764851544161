enum Country {
  NONE = "NONE",
  DE = "DE",
  UA = "UA",
  FR = "FR",
  ES = "ES",
  SE = "SE",
  FI = "FI",
  NO = "NO",
  PL = "PL",
  IT = "IT",
  GB = "GB",
  RO = "RO",
  BY = "BY",
  GR = "GR",
  BG = "BG",
  IS = "IS",
  PT = "PT",
  CZ = "CZ",
  DK = "DK",
  HU = "HU",
  RS = "RS",
  AT = "AT",
  IE = "IE",
  LT = "LT",
  LV = "LV",
  HR = "HR",
  BA = "BA",
  SK = "SK",
  EE = "EE",
  NL = "NL",
  CH = "CH",
  MD = "MD",
  BE = "BE",
  AL = "AL",
  MK = "MK",
  SI = "SI",
  ME = "ME",
  CY = "CY",
  LU = "LU",
  FO = "FO",
  AD = "AD",
  MT = "MT",
  LI = "LI",
  GG = "GG",
  SM = "SM",
  GI = "GI",
  MC = "MC",
  VA = "VA",
}

export default Country;
