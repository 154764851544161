import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import store from "@/ui/store";
import { configModuleMeta } from "@/ui/store/configuration";
import Vue from "vue";

export class BaseClient {
  protected readonly headers: { [index: string]: string } = {
    Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
    accept: "application/json",
    "Access-Control-Allow-Headers": "X-Requested-With,content-type",
    "Content-Type": "application/json",
  };
  protected readonly multipartHeaders: { [index: string]: string } = {
    Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
    accept: "application/json",
    "Access-Control-Allow-Headers": "X-Requested-With,content-type",
    "Content-Type": "multipart/form-data",
  };
  private readonly getJobStatusUri = "/jobs/status";

  protected readonly axios: AxiosInstance;
  protected readonly endpointUri: string;
  protected readonly baseUri: string;

  constructor(endpoint: string) {
    this.baseUri = store.getters[configModuleMeta.getters.getApiUri];
    this.endpointUri = this.baseUri + endpoint;

    this.axios = axios.create();
  }

  private static createRequestConfigInternal(
    uri: string,
    headers: { [index: string]: string }
  ): AxiosRequestConfig {
    const url = new URL(uri);
    return {
      url: url.href,
      headers: headers,
    };
  }

  protected createRequestConfig(uri: string): AxiosRequestConfig {
    return BaseClient.createRequestConfigInternal(uri, this.headers);
  }

  protected createMultipartRequestConfig(uri: string): AxiosRequestConfig {
    return BaseClient.createRequestConfigInternal(uri, this.multipartHeaders);
  }

  protected urlWithId(path: string, id: string): string {
    return `${this.endpointUri}${path}/${id}`;
  }

  protected url(path: string): string {
    return `${this.endpointUri}${path}`;
  }
}
